<template>
  <div class="book-item" :data-id="bookId">
    <b-row align-v="center">
      <b-col cols="5">
        <slot name="cover">
          <b-link class="cover-link" :to="to || '/books/' + bookId"
                  :disabled="!bookId || disabled"
                  :class="[book.type, book.tid]">
            <cover :book="book" fill="auto" size="320">
              <div class="tag" v-bind="coverProps" v-if="coverProps['data-tag']"></div>
            </cover>
          </b-link>
        </slot>
      </b-col>
      <b-col style="min-width: 0;">
        <div class="book-info">
          <div class="book-title">
            <b-link :to="to || '/books/' + bookId"
                    :title="book.title" :disabled="!bookId || disabled">
              <slot name="title">{{book.title}}</slot>
            </b-link>
          </div>
          <div class="info text-muted">
            <slot name="info"></slot>
          </div>
          <div class="actions">
            <slot name="action"></slot>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'bookItem',
  props: {
    value: {
      type: Object,
      required: true
    },
    to: String,
    disabled: Boolean,
    ribbon: String,
    ribbonVariant: String
  },
  computed: {
    book() {
      return this.value
    },
    bookId() {
      return this.book.bookId
    },
    bookType() {
      return this.book.bookType
    },
    coverProps() {
      const props = {}
      if (this.book.finalized || this.book.locked) {
        props['data-tag'] = '已定稿'
        props.class = 'success'
      }
      if (this.book.needRepublish || this.book.needUpgrade) {
        props['data-tag'] = '有更新'
        props.class = 'success'
      }
      if (this.ribbon) {
        props['data-tag'] = this.ribbon
        if (this.ribbonVariant) {
          props.class = this.ribbonVariant
        }
      }
      return props
    }
  }
}
</script>

<style lang="scss" scoped>
  .actions {
    margin-top: 1rem;
    white-space: nowrap;
  }

  .book-title {
    font-size: 1.5rem;
    color: $body-color;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: bold;
    margin-bottom: .75rem;

    a {
      color: inherit;
    }

    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }
  }

  header {
    font-size: 16px;
    font-weight: bold;
  }

  .cover-link {
    display: block;

    &[class*='A5'], &.album, &.balbum, &.blogbook, &.chat-album {
      transform: scale(0.88);
    }

    &[class*='2022-v-'] {
      transform: scale(0.75);
    }

    &.note {
      transform: scale(0.8);
    }
  }

  .info {
    margin-bottom: .5em;
  }

  .tag {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 2px;
    content: '';
    z-index: 2;
  }
</style>
