<template>
  <div class="taobao">
    <loading v-if="loading"/>
    <template v-else>
      <h4 class="mb-3">下单成功，等待前往淘宝支付</h4>
      <square shadow border height="150.6%" :src="poster"/>
      <h4 class="my-3 text-primary">
        <span v-if="isMobile">长按保存图片，搜索进入宝贝详情后发送给客服</span>
        <span v-else>进入淘宝搜索到对应宝贝后，将海报发送给客服</span>
      </h4>
      <b-link class="text-muted" :to="'/pay/' + $route.params.orderNo + '?payType=wxpay'">
        <fa icon="undo"/>
        嫌麻烦，重新用微信或支付宝支付
      </b-link>
    </template>
  </div>
</template>

<script>
import routeData from '@/mixins/route-data'

export default {
  name: 'taobao',
  mixins: [routeData('order')],
  data() {
    return {
      poster: ''
    }
  },
  created() {
    this.interval = setInterval(async () => {
      await this.updateOrder()
      if (this.order.paid) {
        this.$router.replace('/orders/' + this.order.id + '/result')
      }
    }, 2000)
  },
  methods: {
    onLoad() {
      const query = {
        orderNo: this.order.id,
        title: this.order.bookName,
        price: this.order.paidMoney
      }
      this.poster = 'https://canvas.xinshu.me/generate/tb' + this.serialize(query)
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style lang="scss" scoped>
  .taobao {
    max-width: 480px;
    text-align: center;
  }
</style>
