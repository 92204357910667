<template>
  <div class="feedback">
    <page-title v-if="$vnode.data.routerView">帮助反馈</page-title>
    <b-card title="期待您的建议">
      <form @submit.prevent="sendFeedback" class="clearfix" v-if="!submitted">
        <div class="form-group">
          <textarea class="form-control" name="feedback"
                    placeholder="觉得我们的产品好用吗？或者有哪些不爽的地方。优秀建议将会获得抵价券（仅一次）供您下次使用哦。"
                    required rows="4" v-autosize></textarea>
        </div>
        <b-row align-v="center">
          <b-col>
            <b-btn-group size="sm">
              <b-btn :class="{'text-primary': attitude === 2}" @click="attitude = 2" variant="link">
                <fa icon="thumbs-up"/>
                赞
              </b-btn>
              <b-btn :class="{'text-primary': attitude === 0}" @click="attitude = 0" variant="link">
                <fa icon="thumbs-down"/>
                吐槽
              </b-btn>
            </b-btn-group>
          </b-col>
          <b-col cols="auto">
            <b-btn :disabled="submitting" type="submit">
              <fa icon="paper-plane"/>
              提交建议
            </b-btn>
          </b-col>
        </b-row>
      </form>
      <div class="text-center text-center" v-else>
        <h5 class="text-primary">您的反馈很重要，我们正在查看哦</h5>
        <img src="https://static.weixinshu.com/assets/images/deer/love.png!320" alt="" style="width: 120px;">
        <div class="mt-3" v-if="!hasCoupon">
          <b-btn :to="'/my/promotions?couponNo=' + couponNo">点击领取优惠券</b-btn>
        </div>
      </div>
    </b-card>
    <b-card v-if="!noContact" title="客户服务">
      <div>
        <p>如果您需要即时帮助，请点击下方的按钮</p>
        <div class="btn-area">
          <qiyu class="btn-block" text="与客服聊天" variant="primary"></qiyu>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import Qiyu from '@/components/Qiyu'

export default {
  name: 'feedback',
  title: '客服/反馈',
  components: {Qiyu},
  props: {
    noContact: Boolean
  },
  data() {
    return {
      couponNo: 'U61QLJ',
      showFeedback: false,
      submitting: false,
      submitted: false,
      hasCoupon: true,
      attitude: 1
    }
  },
  methods: {
    async sendFeedback({target}) {
      if (!target.feedback.value) {
        this.$alert.error('请填写反馈内容哦')
        return
      }

      try {
        this.submitting = true
        await this.$req.post('/api/feedback', {
          feedback: ['#吐槽#', '#中立#', '#赞#'][this.attitude] + target.feedback.value,
          keys: {
            orderId: this.$route.params.orderNo
          }
        })
        this.submitted = true
        const [coupon] = await this.$req.get('/api/user/coupon_activity/' + this.couponNo)
        this.hasCoupon = coupon && !coupon.get
      } catch (err) {
        console.error(err)
      } finally {
        this.submitting = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .feedback {
    max-width: 480px;

    .btn-link {
      color: $text-muted;

      &.active {
        color: $primary;
      }
    }
  }
</style>
