<template>
  <div class="alipay">
    <div class="guide" v-if="isWechat">
      <img src="../assets/images/pay-guide-android.png" alt="" v-if="isAndroid">
      <img src="../assets/images/pay-guide-ios.png" alt="" v-else>
      <div class="bottom">
        <b-btn variant="outline-white" @click="goBack">返回使用微信支付</b-btn>
      </div>
    </div>

    <div class="text-center" v-else-if="!payUrl">
      <p class="text-danger">
        <fa icon="times-circle" size="5x"/>
      </p>
      <h4>支付参数错误</h4>
      <p>请尝试重新付款</p>
      <div v-if="hasLogin">
        <b-btn :to="'/orders/' + orderNo">返回订单详情</b-btn>
      </div>
    </div>

    <div class="text-center" v-else>
      <p>
        <img src="../assets/images/icons/alipay.svg" alt="" class="icon" style="max-width: 120px;">
      </p>
      <p>
        正在跳转到支付宝...
        <span v-if="remain">{{remain}}</span>
      </p>
      <b-btn variant="link" @click="goBack">返回使用微信支付</b-btn>
    </div>
  </div>
</template>

<script>
import reloadNeeded from '@/mixins/reload-needed'

export default {
  name: 'alipay',
  title: '支付宝支付',
  data() {
    return {
      orderNo: this.$route.params.orderNo,
      payUrl: this.$route.query.payUrl,
      bookId: this.$route.query.bookId,
      remain: 3
    }
  },
  mixins: [reloadNeeded],
  methods: {
    goBack() {
      if (this.orderNo) {
        return this.$router.replace(`/pay/${this.orderNo}?payType=wxpay`)
      }
      return this.$router.go(-1)
    },
    checkOrder() {
      let fetching = false
      let i = 0
      return new Promise(resolve => {
        clearInterval(this.interval)
        this.interval = setInterval(() => {
          if (fetching) {
            return
          }
          if (i > 100) {
            clearInterval(this.interval)
            return
          }
          fetching = true
          i++
          this.$ajax.fetchOrder({orderNo: this.orderNo}).then(order => {
            fetching = false
            if (order.paid) {
              resolve()
            }
          }).catch(() => {
            fetching = false
          })
        }, 2000)
      })
    }
  },
  mounted() {
    if (this.isWechat && this.hasLogin) {
      return this.checkOrder().then(() => {
        this.$router.replace(`/orders/${this.orderNo}/result`)
      })
    }

    if (this.payUrl) {
      this.interval = setInterval(() => {
        this.remain--
        if (this.remain <= 0) {
          clearInterval(this.interval)
          window.top.location.replace(this.payUrl)
        }
      }, 1000)
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style scoped lang="scss">
  .alipay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 15;
    background-color: #fff;
  }

  .guide {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
    z-index: 5;

    p {
      text-align: center;
      margin-top: -25%;
    }
  }

  .bottom {
    position: absolute;
    bottom: 2em;
    left: 0;
    right: 0;
  }

  img {
    max-width: 100%;
  }
</style>
