<template>
  <div class="order">
    <h2 class="page-title">
      订单详情
      <span v-if="!loading && order.bookName">《{{order.bookName}}》</span>
    </h2>
    <template v-if="loading">
      <b-card title="订单详情">
        <table class="table table-borderless">
          <tbody>
          <tr>
            <td>
              <ske width="3em"/>
            </td>
            <td>
              <ske width="3em"/>
            </td>
          </tr>
          <tr>
            <td>
              <ske width="4em"/>
            </td>
            <td>
              <ske width="6em"/>
            </td>
          </tr>
          <tr>
            <td>
              <ske width="2em"/>
            </td>
            <td>
              <div>
                <ske width="10em"/>
              </div>
              <ske width="8em"/>
            </td>
          </tr>
          <tr>
            <td>
              <ske width="2em"/>
            </td>
            <td>
              <div>
                <ske width="10em"/>
              </div>
              <ske width="8em"/>
            </td>
          </tr>
          </tbody>
        </table>
      </b-card>
    </template>
    <template v-else>
      <template v-if="order.book && !isPdf">
        <div class="cashback" v-if="(order.enableCashBack || order.hasCashback) && !isPartnerUser">
          <b-link :to="'/orders/' + order.id + '/cashback'">
            <img alt="返现"
                 src="https://img.xinshu.me/upload/575aed650fff0a571ff3cfbcd8dedb87">
          </b-link>
        </div>

        <template v-if="!isOldOrder">
          <b-card title="物流信息" class="logistics">
            <div class="pt-2">
              <b-row v-for="(item, i) in records" :key="i">
                <b-col cols="auto" class="nowrap">
                  <b :class="{'mid-date': i > 0 && records[i - 1].date === item.date}">
                    {{item.date}}
                  </b>
                  <span class="ml-2">{{item.time}}</span>
                </b-col>
                <b-col cols="12" sm v-html="item.content"/>
              </b-row>
            </div>

            <div class="mt-1 text-right" v-if="order.shipNo">
              <ship :co="order.shipCompany" :no="order.shipNo" class="text-muted">
                前往快递公司网站查询
              </ship>
            </div>
          </b-card>
        </template>
      </template>

      <b-card title="订单信息">
        <table class="table table-borderless">
          <tbody>
          <tr v-if="order.consignee">
            <td>收货人</td>
            <td>{{order.consignee}}</td>
          </tr>
          <tr v-if="order.phone">
            <td>联系电话</td>
            <td>{{order.phone}}</td>
          </tr>
          <tr v-if="order.addr">
            <td>地址</td>
            <td>{{order.addr.split('|').join(' ')}}</td>
          </tr>
          <tr v-if="order.note">
            <td>留言</td>
            <td>{{order.note}}</td>
          </tr>
          <tr v-if="order.status >= 0">
            <td>订单状态</td>
            <td>{{order.status | orderStatus}}</td>
          </tr>
          <tr>
            <td>支付方式</td>
            <td>{{payType}}</td>
          </tr>
          <tr v-if="order.shipCompany && !isPdf">
            <td>物流</td>
            <td>
              <span v-if="/shunfeng|顺丰/.test(order.shipCompany)">顺丰快递</span>
              <span v-else>{{order.shipCompany | shipCompany}}</span>
              <span v-if="order.shipNo">{{order.shipNo}}</span>
            </td>
          </tr>
          <tr v-if="isPdf">
            <td>下载链接</td>
            <td>
              <template v-if="!order.paid">订单未付款</template>
              <template v-else-if="order.printStatus === 1">等待导出PDF</template>
              <template v-else-if="order.printStatus !== 5">正在导出PDF</template>
              <b-link :to="'/orders/' + order.id + '/pdf'" v-else>查看下载链接</b-link>
            </td>
          </tr>
          <tr v-if="order.package">
            <td>套装内容</td>
            <td>
              <b-link :to="`/orders/${$route.params.orderNo}/codes`">查看套装内兑换码</b-link>
            </td>
          </tr>
          <template v-if="order.hasRepair">
            <tr>
              <td>售后状态</td>
              <td>
                <span v-if="order.repairStatus === 0">等待处理</span>
                <span v-else-if="order.repairStatus < 3">处理中</span>
                <span v-else>已完成</span>
                <b-link :to="'/orders/' + order.id + '/repair'">查看</b-link>
              </td>
            </tr>
          </template>

          <template v-if="order.hasCashback">
            <tr>
              <td>返现状态</td>
              <td>
                <span v-if="order.cashBackStatus === 0">等待审核</span>
                <span v-else-if="order.cashBackStatus <= 2">正在审核</span>
                <span v-else>已完成</span>
                <b-link :to="'/orders/' + order.id + '/cashback'">查看</b-link>
              </td>
            </tr>
          </template>

          <tr>
            <td colspan="2">
              <hr class="my-0">
            </td>
          </tr>

          <tr v-if="order.book">
            <td>书名</td>
            <td>
              <span v-if="!order.book.bookId">{{order.bookName}}</span>
              <template v-else>
                <b-link :to="'/books/' + order.book.bookId">{{order.bookName}}</b-link>
                <template v-if="order.book.pages">/ {{order.book.pages}}页</template>
              </template>
            </td>
          </tr>
          <tr v-if="order.bookType">
            <td>作品类型</td>
            <td>{{order.bookType | productName}}</td>
          </tr>
          <tr v-if="order.price >= 0">
            <td>价格</td>
            <td>{{$rmb(order.price)}}</td>
          </tr>
          <tr v-if="order.shipMoney">
            <td>邮费</td>
            <td>{{$rmb(order.shipMoney)}}</td>
          </tr>
          <tr>
            <td>实付</td>
            <td>{{$rmb(order.paidMoney)}}</td>
          </tr>
          <tr v-if="order.couponNo && order.couponPrice">
            <td>优惠券</td>
            <td>
              {{order.couponNo}} /
              {{order.couponPrice | couponValue}}
            </td>
          </tr>
          <tr v-if="order.book && order.book.redeemCode">
            <td>兑换码</td>
            <td>{{order.book.redeemCode}}</td>
          </tr>
          <tr>
            <td>数量</td>
            <td>{{order.count}} 套</td>
          </tr>
          <tr v-if="order.bindingName">
            <td>装帧</td>
            <td>{{order.bindingName}}</td>
          </tr>
          <tr v-if="order.printType">
            <td>版式</td>
            <td>{{order.book.typesetName}}</td>
          </tr>
          <tr v-if="order.invoice">
            <td>发票信息</td>
            <td>{{order.invoice.title}} / {{order.invoice.category}}</td>
          </tr>

          <tr>
            <td colspan="2">
              <hr class="my-0">
            </td>
          </tr>

          <tr>
            <td>订单号</td>
            <td>{{order.id}}</td>
          </tr>
          <tr v-if="order.paidTime">
            <td>支付时间</td>
            <td>
              <datetime :value="order.paidTime" full/>
            </td>
          </tr>
          <tr>
            <td>创建时间</td>
            <td>
              <datetime :value="order.createTime" full/>
            </td>
          </tr>
          </tbody>
        </table>
      </b-card>

      <footer class="text-right">
        <template v-if="!order.paid">
          <b-btn @click="removeOrder(order)">删除订单</b-btn>
          <b-btn :to="'/pay/' + order.id" variant="primary">
            <fa icon="credit-card"/>
            继续付款
          </b-btn>
        </template>

        <template v-else>
          <b-btn v-if="!isPartnerUser && order.status < 2" to="edit" append>修改订单</b-btn>

          <b-btn variant="warning" :to="'/orders/' + order.id + '/repair'"
                 v-if="(order.enableRepair || order.hasRepair) && !isPartnerUser">
            <fa icon="wrench"/>
            售后
          </b-btn>

          <template v-if="canBuy">
            <b-btn :to="'/books/' + order.book.bookId + '/buy?pdf=1'" variant="primary">
              <fa icon="shopping-cart"/>
              加印 / PDF
            </b-btn>
          </template>
        </template>

        <div class="float-left">
          <b-btn @click="$router.go(-1)" v-if="$store.state.prevRoute">
            返回
          </b-btn>
          <b-btn to="/orders" exact v-else-if="!isAgentUser">
            返回订单列表
          </b-btn>
        </div>
      </footer>
    </template>
  </div>
</template>

<script>
import { chain } from 'lodash'
import { offlineProducts } from '@/config'

import route from '@/mixins/route-data'

export default {
  name: 'order',
  components: {
    Ship: require('@/components/Shipment').default
  },
  title: '订单详情',
  mixins: [route('order')],
  beforeRouteEnter(to, from, next) {
    if (/^5\d{14}/.test(to.params.orderNo)) {
      return next('/package-orders/' + to.params.orderNo)
    }
    next()
  },
  computed: {
    payType() {
      if (!this.order.paidType) {
        return ''
      }
      return {
        balance: '余额支付',
        wxpay: '微信支付',
        alipay: '支付宝'
      }[this.order.paidType.split('_')[0]] || '其他'
    },
    canBuy() {
      const order = this.order
      const book = order.book || {}
      const bookType = book.tid || book.bookType
      return order.paid && (book.finalized || book.locked) && !offlineProducts.includes(bookType)
    },
    isOldOrder() {
      return this.$day().diff(this.order.paidTime, 'day') > 60
    },
    isPdf() {
      return this.order.binding === 'pdf'
    },
    records() {
      const order = this.order
      const records = order.logistics?.records || order.logistics?.data || []
      if (!records.length && order.paidTime) {
        records.push({
          time: order.paidTime,
          content: '商品已经下单'
        })

        if (order.shipTime && order.shipNo) {
          records.push({
            time: order.shipTime,
            content: '等待快递揽收'
          })

          if (this.$day(order.shipTime).diff(new Date(), 'hour') < -24) {
            records.push({
              time: new Date(),
              content: '暂无更多物流信息，请点击下方链接查看'
            })
          }
        }
      }

      return chain(records).map(i => {
        const moment = this.$day(i.time || i.AcceptTime)
        const content = i.content || i.context || i.AcceptStation
        const date = moment.format('YYYY-MM-DD')
        const time = moment.format('HH:mm:ss')
        const datetime = moment.toJSON()
        return {date, datetime, time, content}
      }).orderBy(i => new Date(i.datetime), 'desc').value()
    }
  },
  methods: {
    async removeOrder() {
      const confirmed = await this.$dialog.confirm({
        title: '删除订单',
        content: '是否要删除当前订单'
      })
      if (!confirmed) {
        return
      }
      return this.$req.get('/api/order/' + this.order.id + '/delete').then(() => {
        this.$alert.success('订单删除成功！')
        this.$router.replace('/orders')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.order {
  max-width: 640px;

  .cashback, .repair {
    border-radius: $border-radius;
    overflow: hidden;
    margin-bottom: 1em;
  }

  .logistics {
    word-break: break-all;
  }

  .table {
    margin-bottom: 0;
  }

  td:first-child {
    padding-left: 0;
    white-space: nowrap;
    width: 1%;
  }

  td:last-child {
    padding-right: 0;
  }

  @include media-breakpoint-up(sm) {
    .mid-date {
      visibility: hidden;
      pointer-events: none;
    }
  }
}
</style>
