<template>
  <div class="pay">
    <h2 class="page-title">支付订单</h2>
    <b-card v-if="status === 2">
      <p class="text-success">
        <fa icon="check-circle" size="5x"/>
      </p>
      <h4>支付已完成</h4>
      <div v-if="orderStatus.isOwn">
        正在返回
        <br>
        请稍候...
      </div>
      <div v-else>
        感谢您的付款
        <br>
        请关闭本页面
      </div>
    </b-card>
    <b-card v-else-if="status === 1 && payType === 'wxpay_web_friend'">
      <qrcode class="mb-2" :text="qrcodeText" size="60%" icon="wxpay"/>
      <h4>发送上方二维码给微信好友</h4>
      <h5>可截图、拍照或长按保存</h5>
      <div>好友长按识别后即可支付</div>
      <div>
        <b-link @click="switchPayType('wxpay')">我要自己付款</b-link>
      </div>
    </b-card>
    <b-card v-else-if="status === 1 && payType === 'wxpay_web'">
      <qrcode class="mb-2" :text="qrcodeText" size="60%" icon="wxpay"/>
      <template v-if="isWechat">
        <h4>长按上方二维码识别</h4>
        <div>继续完成支付即可</div>
      </template>

      <template v-else>
        <h4>打开微信扫描上方二维码</h4>
        <div>扫码后请继续在微信中完成支付</div>
      </template>

      <div class="my-2">
        微信余额不足？
        <b-link @click="switchPayType('alipay')">使用支付宝</b-link>
      </div>

      <div class="mt-2" v-if="canPayByOthers">
        或者
        <b-link @click="switchPayType('wxpay_web_friend')">请好友代付</b-link>
      </div>
    </b-card>
    <b-card v-else-if="status === 1 && payType === 'wxpay_wap'">
      <p>
        <img src="../assets/images/icons/wxpay.svg" alt="" class="icon">
      </p>
      <h4>正在调起微信支付</h4>
      <div>
        如果长时间没有响应，请
        <br>
        <b-link @click="switchPayType('wxpay_web')">点此使用二维码支付</b-link>
      </div>
    </b-card>
    <b-card v-else-if="status === 1 && payType === 'wxpay_h5'">
      <p>
        <img src="../assets/images/icons/wxpay.svg" alt="" class="icon">
      </p>
      <h4>正在调起微信</h4>
      <div>
        如果长时间没有响应，请
        <br>
        <b-link @click="switchPayType('wxpay_web')">点此使用二维码支付</b-link>
      </div>
    </b-card>
    <b-card v-else-if="status === 1 && payType.startsWith('alipay')">
      <p>
        <img src="../assets/images/icons/alipay.svg" alt="" class="icon">
      </p>
      <h4>正在处理...</h4>
      <div>请稍候...</div>
    </b-card>
    <b-card v-else-if="status === 1 && payType === 'balance'">
      <p>
        <img src="../assets/images/icons/balance.svg" alt="" class="icon">
      </p>
      <h4>正在查询余额...</h4>
      <div>请稍候...</div>
    </b-card>
    <b-card v-else-if="status === -1">
      <fa icon="history" class="icon text-warning"/>
      <h4>支付已超时</h4>
      <div class="btn-area">
        <b-btn block @click="startPay()">重新发起支付</b-btn>
      </div>
      <div class="my-2">
        微信支付失败？
        <b-link @click="switchPayType('alipay')">更换支付宝</b-link>
      </div>
      <div class="mt-2" v-if="canPayByOthers">
        您还可以
        <b-link @click="switchPayType('wxpay_web_friend')">请好友代付</b-link>
      </div>
    </b-card>
    <b-card v-else-if="status === -2">
      <fa icon="exclamation-triangle" class="icon text-danger"/>
      <h4>支付已取消</h4>
      <div>
        <b-btn block @click="startPay()">重新发起支付</b-btn>
      </div>

      <div class="mt-2" v-if="canPayByOthers">
        您还可以
        <b-link @click="switchPayType('wxpay_web_friend')">请好友代付</b-link>
      </div>
    </b-card>
    <b-card v-else-if="status === -3">
      <fa icon="exclamation-triangle" class="icon text-danger"/>
      <h4>支付遇到错误</h4>
      <p>如已扣款请联系客服</p>
      <pre v-if="error">{{error.message}}</pre>
      <div v-if="orderStatus.paidType === 'balance'">
        余额不足？
        <b-link to="/my/recharge">前往充值</b-link>
      </div>
    </b-card>
    <b-card v-else>
      <p>正在为您处理支付请求</p>
      <fa icon="hourglass-half" class="icon"/>
      请稍候...
    </b-card>

    <div class="text-center bottom" v-if="!isMiniProgram">
      <qiyu variant="link">联系客服</qiyu>
    </div>
  </div>
</template>

<script>
import copyText from 'clipboard-copy'

import Qrcode from '@/components/QRCode.vue'
import reloadNeeded from '@/mixins/reload-needed'
import routeData from '@/mixins/route-data'
import { wxPay } from '@/modules/wechat'

import { state } from '@/vuex/store'
import Qiyu from '@/components/Qiyu'
import { until } from '@/utils/wait-for'

export default {
  name: 'pay',
  title: '订单支付',
  components: {Qiyu, Qrcode},
  mixins: [routeData('orderStatus'), reloadNeeded],
  beforeRouteEnter(to, from, next) {
    const isMiniProgram = window.__wxjs_environment === 'miniprogram'
    if (isMiniProgram) {
      // 小程序中使用原生的支付页面
      const path = '/pages/wxpay/wxpay?token=' + state.token + '&orderNo=' + to.params.orderNo
      until(() => window.wx).then(() => {
        window.wx.miniProgram.redirectTo({url: path})
      })
      return next(false)
    }
    next()
  },
  data() {
    return {
      qrcodeText: '',
      status: 0,
      payType: '',
      error: null
    }
  },
  computed: {
    orderNo() {
      return this.$route.params.orderNo
    },
    realOrderNo() {
      return this.$route.params.orderNo.replace(/^[a-zA-Z]+/, '')
    },
    canPayByOthers() {
      return this.orderStatus.isOwn
    }
  },
  methods: {
    onLoad() {
      const redirect = this.$route.query.redirect
      if (redirect) {
        // URL中指定了redirect参数，记录后在支付完成页面再做跳转
        localStorage.setItem('order.redirect.' + this.orderNo, redirect)
      }

      if (this.orderStatus.paid) {
        this.onPaySuccess()
        return
      }

      const payType = this.$route.query.payType || this.orderStatus.paidType
      this.payType = this.getPayType(payType)
      this.startPay()
    },
    onPaySuccess() {
      this.status = 2
      if (!this.orderStatus.isOwn) {
        return
      }
      if (this.navigating) {
        return
      }
      clearInterval(this.checkingInterval)
      this.$router.replace('/orders/' + this.orderNo + '/result')
      this.navigating = true
    },
    async copyLink() {
      const link = location.origin + '/pay/' + this.orderNo
      try {
        await copyText(link)
        this.$alert.success('付款链接已复制，请发送给好友')
      } catch (err) {
        window.prompt('请手动复制付款链接发送给好友', link)
      }
      this.$gtag.event('request_pay', {
        event_label: this.orderNo
      })
    },
    switchPayType(payType) {
      const oldPayType = this.payType
      this.payType = this.getPayType(payType)
      if (oldPayType === 'wxpay_web' && payType === 'wxpay_web_friend') {
        return
      }
      this.startPay()
    },
    getPayType(payType) {
      if (/friend|balance/.test(payType)) {
        return payType
      }
      let deviceType = window.isMobile ? 'wap' : 'web'
      if (/wxpay/.test(payType)) {
        // 微信中使用 wxpay_wap
        deviceType = window.isWechat ? 'wap' : 'web'
        if (deviceType === 'web' && window.isMobile) {
          // 浏览器中使用 wxpay_h5
          deviceType = 'h5'
        }
      }
      return [payType.split('_')[0], deviceType].filter(Boolean).join('_')
    },
    async startPay() {
      try {
        this.status = 1
        this.checkPayStatus()

        switch (this.payType) {
          case 'balance':
            await this.payWithBalance()
            break
          case 'taobao':
            this.$router.replace('/pay/' + this.orderNo + '/taobao')
            break
          default:
            await this.fetchPayParams().then(this.doPay)
        }
      } catch (err) {
        if (/cancel/.test(err.message)) {
          this.status = -2
        } else {
          this.$alert.error(err.message)
          this.status = -3
          this.error = err
        }
      }
    },
    payWithBalance() {
      return this.$req.post(`/api/order/${this.realOrderNo}/pay_with_balance`)
    },
    fetchPayParams() {
      let urlPrefix = '/api/order/'
      const urlSuffix = '/pay_info?pay_type=' + this.getPayType(this.payType)

      if (/^2\d{14}$/.test(this.realOrderNo)) {
        // 充值订单
        urlPrefix = '/api/gift_card/order/'
      }

      if (/^3\d{14}$/.test(this.realOrderNo)) {
        // 泓达加盟订单
        urlPrefix = '/api/weimeng/orders/'
      }

      if (/^4\d{14}$/.test(this.realOrderNo)) {
        // 泓达礼品卡订单
        urlPrefix = '/api/order/coupon_cards/orders/'
      }

      if (/^5\d{14}$/.test(this.realOrderNo)) {
        // 套装订单
        urlPrefix = '/api/order/packages/orders/'
      }

      if (/^20\d{14}$/.test(this.realOrderNo)) {
        // 照片书订单
        urlPrefix = '/api/order/albums/orders/'
      }

      return this.$req.get(urlPrefix + this.realOrderNo + urlSuffix)
    },
    doPay(params) {
      const orderNo = this.realOrderNo
      const {payType, status, redirectUrl} = params
      if (status === 'fail') {
        this.status = -3
        return
      }
      if (redirectUrl) {
        return location.replace(redirectUrl)
      }
      const redirect = 'https://weixinshu.com/orders/' + orderNo + '/result?token=' + this.token
      switch (payType) {
        case 'wxpay_wap':
          return wxPay(params)
        case 'wxpay_web':
        case 'wxpay_web_friend':
          this.qrcodeText = params.codeUrl
          return
        case 'wxpay_h5':
          location.href = params.mwebUrl + '&redirect_url=' + encodeURIComponent(redirect)
          return
        case 'alipay_wap':
        case 'alipay_web':
          return this.$router.replace({
            name: 'alipay',
            params: {orderNo},
            query: {
              payUrl: params.url
            }
          })
      }
    },

    async checkPayStatus() {
      console.log('Checking order status')
      let i = 0
      let fetching = false
      clearInterval(this.checkingInterval)
      this.checkingInterval = setInterval(() => {
        if (i > 100 || this.status === -1) {
          this.status = Math.min(-1, this.status)
          clearInterval(this.checkingInterval)
          return
        }
        if (fetching) {
          return
        }
        i++
        fetching = true
        this.fetchOrderStatus().then(status => {
          if (status && status.paid) {
            clearInterval(this.checkingInterval)
            this.onPaySuccess()
          }
        }).catch(err => {
          console.error(err.message)
        }).then(() => {
          fetching = false
        })
      }, 3000)
    }
  },
  beforeDestroy() {
    this.status = -1
    clearInterval(this.checkingInterval)
  }
}
</script>

<style scoped lang="scss">
.pay {
  max-width: 480px;

  .card {
    text-align: center;
  }

  .icon {
    margin: 2rem auto;
    display: block;
    font-size: 5rem;
    max-width: 8rem;
  }
}
</style>
