<template>
  <div class="edit-order">
    <h2 class="page-title">订单修改</h2>
    <loading v-if="loading"/>
    <template v-else-if="!order.paid">
      <empty icon="credit-card">订单未支付，不支持修改哦</empty>
    </template>
    <template v-else-if="order.status >= 2">
      <empty icon="credit-card">订单已交付印厂，不支持修改哦</empty>
    </template>
    <template v-else>
      <b-card title="订单概览">
        <table class="table table-borderless mb-0">
          <tbody>
          <tr>
            <td class="nowrap">订单号</td>
            <td>{{order.id}}</td>
          </tr>
          <tr>
            <td class="nowrap">作品名称</td>
            <td>{{order.bookName}}</td>
          </tr>
          <tr>
            <td>价格</td>
            <td>{{$rmb(order.paidMoney)}}</td>
          </tr>
          <tr>
            <td>留言</td>
            <td>{{order.note}}</td>
          </tr>
          </tbody>
        </table>
      </b-card>

      <b-card title="修改订单">
        <b-form-group>
          <b-select v-model="type">
            <option value="">选择您要修改的类型</option>
            <option :value="i" :key="i" v-for="i in types">{{i}}</option>
          </b-select>
        </b-form-group>

        <template v-if="type === '修改收货信息' && !order.enableUpdateAddr">
          <empty icon="truck">
            <p>您已自助修改过收货信息，如需再次修改，请联系客服</p>
            <qiyu variant="success"/>
          </empty>
        </template>

        <template v-if="type === '申请退款'">
          <p>请点击下面的按钮联系客服申请退款</p>
          <qiyu variant="success" block>联系客服申请退款</qiyu>
        </template>

        <template v-else-if="type === '修改收货信息'">
          <p>可自助修改收货信息，请在下方修改后确认。<b>仅可修改1次。</b></p>
          <b-form-group label="收货人">
            <b-input v-model="order.consignee" placeholder="收货人姓名" maxlength="20"/>
          </b-form-group>
          <b-form-group label="手机号">
            <b-input type="tel" v-model="order.phone" placeholder="收货人手机号" maxlength="11"/>
          </b-form-group>
          <b-form-group label="收货地址">
            <address-picker v-model="order.addr"/>
          </b-form-group>
          <b-btn block variant="primary" :disabled="!canSave || submitting" @click="onSubmit">确认修改</b-btn>
        </template>

        <template v-else-if="type && order.note.includes(type)">
          <p>您已确认{{type}}，订单将不会自动进入印刷流程。请修改作品后，联系客服为您同步至订单，或等待客服确认。</p>
          <qiyu ref="qiyu" block variant="success">立即联系客服</qiyu>
        </template>

        <template v-else-if="type">
          <p>请先点击下面的按钮确认，确认后您的订单将不会自动进入印刷流程。</p>

          <b-btn block variant="primary" :disabled="submitting" @click="onSubmit">确认要修改并暂停打印</b-btn>
        </template>

        <b-btn block disabled v-else variant="primary">请先选择修改类型</b-btn>
      </b-card>
    </template>
  </div>
</template>

<script>
import AddressPicker from '@/components/AddressPicker'
import route from '@/mixins/route-data'
import Qiyu from '@/components/Qiyu'

export default {
  name: 'editOrder',
  mixins: [route('order')],
  components: {Qiyu, AddressPicker},
  data() {
    return {
      type: '',
      types: [
        '修改收货信息',
        '修改封面',
        '修改内页',
        '修改插页',
        '修改排版',
        '修改其他部分',
        '申请退款'
      ],
      oldInfo: {
        consignee: '',
        phone: '',
        addr: ''
      },
      address: '',
      submitting: false
    }
  },
  computed: {
    canSave() {
      const oldInfo = this.oldInfo
      const {consignee, phone, addr} = this.order
      return consignee && phone && addr &&
        (consignee !== oldInfo.consignee || phone !== oldInfo.phone || addr !== oldInfo.addr)
    }
  },
  methods: {
    onLoad() {
      this.oldInfo.consignee = this.order.consignee
      this.oldInfo.phone = this.order.phone
      this.oldInfo.addr = this.order.addr

      this.type = (this.order.note.match(/#(修改[^#]+)#$/) || [])[1]
      if (!this.types.includes(this.type)) {
        this.type = ''
      }
    },
    async onSubmit() {
      try {
        this.submitting = true
        if (this.type === '修改收货信息') {
          await this.saveOrder({
            consignee: this.order.consignee,
            phone: this.order.phone,
            addr: this.order.addr
          })
          this.order.enableUpdateAddr = false
          this.$alert.success('收货信息修改成功')
          this.$router.go(-1)
        } else {
          const note = '#' + this.type + '#' + (this.order.note || '')
          await this.saveOrder({note})
          this.order.note = note
          const confirmed = await this.$dialog.confirm({
            title: '联系客服',
            content: '已为您暂停打印，是否要立即联系客服？',
            okTitle: '立即联系',
            cancelTitle: '稍后联系'
          })
          if (!confirmed) {
            return
          }
          this.$refs.qiyu.activate()
        }
      } finally {
        this.submitting = false
      }
    },
    saveOrder(data) {
      return this.$req.post('/api/order/' + this.order.id, data)
    }
  }
}
</script>

<style lang="scss" scoped>
  .edit-order {
    max-width: 560px;

    td:first-child {
      padding-left: 0;
    }
  }
</style>
