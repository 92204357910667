<template>
  <div class="cashback">
    <h2 class="page-title">晒单返现</h2>
    <loading v-if="loading"/>
    <template v-else-if="order.enableCashBack || order.hasCashback || $route.query.force">
      <b-card v-if="!hasTicket">
        <p>
          <img alt="返现" :src="cashbackConfig.banner">
        </p>

        <h5>返现规则</h5>
        <ol>
          <li v-for="rule in cashbackConfig.rules" v-html="rule" :key="rule"></li>
        </ol>

        <hr>

        <form @submit.prevent="onSubmit" novalidate>
          <b-form-group label="写点感受">
            <textarea :disabled="!!finished" class="form-control" minlength="15" maxlength="500"
                      placeholder="写写您的出书感受吧（至少 15 字哦）" v-word-count
                      required rows="3" v-autosize v-model.trim="ticket.content"/>
          </b-form-group>

          <b-form-group label="晒点图片（请上传当前订单的图片哦）">
            <grid cols="3">
              <grid-item :key="item.id" v-for="(item, index) in fixedItems" padding="0 5px">
                <upload-zone cols="1" hide-exceeded max="1" simple v-model="pics[index]"/>
                <div class="text-center mt-2">{{item.text}}</div>
                <div class="text-center">
                  <b-link @click="example = item.id; $bvModal.show('example')">
                    <fa icon="info-circle"/>
                    查看示例
                  </b-link>
                </div>
              </grid-item>
            </grid>
          </b-form-group>

          <divider>上传更多图片，获取更多返现</divider>

          <upload-zone max="3" cols="4" size="320x320" v-model="morePics" simple/>

          <div class="btn-area">
            <b-btn :disabled="submitting || !ticket.content" block type="submit" variant="primary">
              <fa icon="spinner" spin v-if="submitting"/>
              提交审核
            </b-btn>
          </div>
        </form>
      </b-card>

      <template v-else>
        <b-card>
          <form @submit.prevent="onSubmit" novalidate>
            <b-form-group label="晒单状态">
              <b v-if="success">已发放返现</b>
              <b v-else-if="failed">审核未通过</b>
              <b v-else-if="ticket.status === 0">等待审核</b>
              <b v-else>审核中</b>
            </b-form-group>

            <b-form-group label="发起时间">
              <datetime :value="ticket.createAt"/>
            </b-form-group>

            <b-form-group label="晒单内容">
              <textarea :disabled="finished" class="form-control" minlength="15" maxlength="500"
                        v-if="editing"
                        placeholder="写写您的出书感受吧（至少 15 字哦）" v-word-count
                        required rows="3" v-autosize v-model="ticket.content"></textarea>
              <div class="text-pre-wrap" v-else>{{ticket.content}}</div>
            </b-form-group>

            <grid cols="3">
              <grid-item :key="item.id" v-for="(item, index) in fixedItems">
                <upload-zone :readonly="finished || !editing" cols="1" hide-exceeded max="1"
                             simple v-model="pics[index]"/>
                <div class="text-center mt-2">{{item.text}}</div>
              </grid-item>
            </grid>

            <template v-if="morePics.length || editing">
              <divider>更多图片</divider>
              <upload-zone :readonly="!editing" max="3" cols="4" size="320x320" v-model="morePics"
                           simple/>
            </template>

            <div class="btn-area" v-if="!finished">
              <b-btn :disabled="submitting" block type="submit" variant="primary" v-if="editing">
                <fa icon="spinner" spin v-if="submitting"/>
                保存修改
              </b-btn>
            </div>
          </form>

          <div class="btn-area" v-if="!editing && !finished">
            <b-btn block @click="editing = true">修改申请</b-btn>
          </div>
        </b-card>

        <template v-if="cashback.replys.length">
          <b-card>
            <div class="message" v-for="msg in cashback.replys" :key="msg.id">
              <div class="mb-1">
                <b>
                  <avatar :src="msg.replier.avatar" size="2em"></avatar>
                  {{msg.replier.name}}
                </b>
                <span class="text-muted float-right">
                  <datetime :value="msg.createAt"/>
                </span>
              </div>
              <div class="text-pre-wrap">{{msg.content}}</div>
              <image-zone :value="msg.picList" v-if="msg.picList && msg.picList.length"/>
            </div>
          </b-card>
          <b-card>
            <b-form-group label="留言内容">
              <textarea class="form-control" placeholder="说点什么吧" rows="2" v-autosize
                        v-model="reply.content"></textarea>
            </b-form-group>

            <upload-zone cols="5" simple size="320x320" v-model="reply.picList"/>

            <b-button :disabled="sending" @click="sendReply" variant="primary">
              <fa icon="paper-plane"/>
              发送{{sending ? '中' : ''}}
            </b-button>
          </b-card>
        </template>
      </template>

      <b-modal hide-footer id="example" title="图片示例" size="sm">
        <div v-html="activeExample.demand"></div>
        <img :alt="activeExample.demand" :src="item" class="example"
             v-for="item in activeExample.example" :key="item">
      </b-modal>
    </template>

    <empty emotion="cry" v-else>
      <p>对不起，此订单不支持返现哦</p>
      <b-btn exact to="/orders">返回</b-btn>
    </empty>
  </div>
</template>

<script>
import { isEmpty, pick } from 'lodash'
import routeData from '@/mixins/route-data'

import ImageZone from '@/components/ImageZone.vue'
import UploadZone from '@/components/UploadZone.vue'

export default {
  name: 'cashback',
  title: '晒订单赢返现',
  mixins: [routeData('cashbackConfig'), routeData('order'), routeData('cashback')],
  components: {UploadZone, ImageZone},
  data() {
    return {
      activeIndex: -1,
      ticket: {
        title: this.$route.params.orderNo + ' - 订单返现',
        status: 0,
        priority: 1,
        content: '',
        categoryInfo: this.$route.params.orderNo,
        category: 3,
        picList: []
      },

      submitting: false,
      sending: false,
      editing: false,
      reply: {
        content: '',
        picList: []
      },

      example: '',
      pics: [],
      morePics: [],

      fixedItems: [
        {
          id: 'cover',
          text: '封面图',
          example: ['https://img.xinshu.me/resource/b76591fba29b44e29a89492984cbe85d'],
          demand: '拍摄实物书封面图，清晰不歪斜'
        }, {
          id: 'inner',
          text: '内页图',
          example: ['https://img.xinshu.me/resource/298c8528c99a402480ac44c15f5fdcf5'],
          demand: '选取图片最美观的对页，保证光线充足，拍摄清晰不模糊'
        }, {
          id: 'screenshot',
          text: '朋友圈截图',
          example: [
            'https://img.xinshu.me/resource/5a95e434bcd4490b878c097b51076d39',
            'https://img.xinshu.me/resource/c44f735a913b49c6b19fc617a1a10771'
          ],
          demand: '朋友圈的图片中请出现心书的二维码。<br>可以拍摄实物书背面及宣传单上的二维码、下载下方的二维码或使用您的代言人海报（可从个人中心下载，朋友扫码下单后您可获得收益）'
        }
      ]
    }
  },
  computed: {
    success() {
      return this.cashback && this.cashback.status === 4
    },
    failed() {
      return this.cashback && this.cashback.status === 5
    },
    finished() {
      return this.success || this.failed
    },
    hasTicket() {
      return !isEmpty(this.cashback)
    },
    activeExample() {
      return this.fixedItems.find(i => i.id === this.example) || {}
    }
  },
  methods: {
    onLoad() {
      if (!isEmpty(this.cashback)) {
        this.ticket.id = this.cashback.id
        this.ticket.content = this.cashback.content
        this.ticket.createAt = this.cashback.createAt
        this.pics = this.cashback.picList.slice(0, 3)
        this.morePics = this.cashback.picList.slice(3)
      }
    },
    onSubmit() {
      if (!this.ticket.content) {
        this.$alert.error('请填写晒单描述哦')
        return
      }

      if (this.ticket.content.length < 15) {
        this.$alert.error('请补全晒单描述至最少 15 字哦')
        return
      }

      if (this.pics.length < 3 || !this.pics.every(Boolean)) {
        this.$alert.error('请补全所有的必要晒单图片哦')
        return
      }

      let data = Object.assign({}, this.ticket)
      data.picList = this.pics.concat(this.morePics)
      data = pick(
        data,
        ['title', 'content', 'picList', 'category', 'categoryInfo', 'status', 'priority']
      )

      this.submitting = true

      if (this.ticket.id) {
        return this.$req.post('/api/helpdesk/request/edit/' + this.ticket.id, data).then(() => {
          this.submitting = false
          this.editing = false
          this.$alert.success('返现申请已修改')
        }).catch(() => {
          this.submitting = false
        })
      }

      return this.$req.post('/api/helpdesk/request/add', data).then(() => {
        this.$alert.success('返现申请已提交')
        this.submitting = false
        this.editing = false
        this.updateCashback().then(() => {
          this.onLoad()
        })
      }).catch(() => {
        this.submitting = false
      })
    },
    async sendReply() {
      if (!this.reply || this.sending) {
        return
      }
      this.sending = true
      try {
        await this.$req.post(`/api/helpdesk/request/${this.ticket.id}/reply/add`, {
          content: this.reply.content,
          picList: this.reply.picList
        })
        const cashback = await this.fetchCashback()
        this.reply = ''
        this.cashback = cashback
      } catch (err) {
      }
      this.sending = false
    },
    removeImage(index) {
      this.$set(this.pics, index, '')
    }
  }
}
</script>

<style lang="scss" scoped>
.cashback {
  max-width: 560px;

  .images {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    text-align: center;
    margin-top: 1rem;
  }

  ol {
    padding-left: 1.25em;

    li {
      line-height: 1.8;
    }
  }

  .card {
    margin-bottom: $grid-gutter-width-base;
  }

  .message {
    margin-bottom: 2em;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .item {
    width: 33.333%;
    margin-bottom: 1rem;

    .image {
      position: relative;
      margin-bottom: .5em;

      &:before {
        content: '';
        display: block;
        padding-bottom: 100%;
      }

      &.disabled {
        cursor: not-allowed;
        pointer-events: none;
      }

      .mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 95%;
        height: 95%;
        font-size: 12px;
        margin: auto;
        background-color: rgba(0, 0, 0, .5);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 2;
        color: #fff;
        border-radius: 4px;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 95%;
        height: 95%;
        z-index: 1;
        object-fit: cover;
        background-color: #eee;
        border-radius: 4px;
      }
    }

    .add-image {
      position: relative;
      cursor: pointer;
      text-align: center;

      &:hover:after {
        background-color: #efefef;
      }

      &:after {
        content: '';
        display: block;
        border: 1px dashed $input-border-color;
        top: 2.5%;
        left: 2.5%;
        right: 2.5%;
        bottom: 2.5%;
        position: absolute;
        transition: .3s;
        border-radius: 4px;
      }

      i {
        position: absolute;
        width: 1em;
        height: 1em;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        font-size: 1.25em;
        z-index: 2;
      }
    }

    .mask.remove {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5em;
      color: #fff;

      i {
        pointer-events: all;
        cursor: pointer;
      }
    }
  }
}

.example {
  border: 5px solid $hr-border-color;
  border-radius: 8px;
  margin-top: 1rem;
}
</style>
