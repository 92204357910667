<template>
  <div class="purchase">
    <loading v-if="loading"/>
    <form @submit.prevent="onSubmit" name="orderForm" v-else>
      <b-card class="card-book-info" v-if="book.title">
        <template slot="header">
          作品信息
          <div class="float-right text-muted" v-if="isAgent">代理下单</div>
        </template>
        <b-row align-v="center">
          <b-col cols="4">
            <cover :book="book" fill size="320"/>
          </b-col>
          <b-col style="min-width: 0;">
            <p class="book-title text-overflow">
              <book-icon :value="book.bookType" colorful/>
              <b>{{book.title}}</b>
            </p>
            <div class="text-overflow">
              <template v-if="book.author">作者：{{book.author}}</template>
              <datetime :value="book.createdAt" calendar prefix="创建于 " v-else/>
            </div>
            <div v-if="book.type">版式：{{book.typesetName}}</div>
            <div v-if="book.pages">页数：{{book.pages}}</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card class="card-binding" header="装帧选择" v-if="bindings && bindings.length">
        <template slot="header">
          装帧选择
          <div class="float-right" v-if="bindings.length > 1 && !book.isAlbum">
            <b-link v-b-modal="'typesetDetail'">查看不同装帧的区别</b-link>
          </div>
        </template>
        <select-group :options="bindings" required v-model="order.binding"></select-group>
        <div class="mt-2 text-muted" v-if="book.pages > maxPageSize">
          <fa icon="exclamation-triangle"/>
          作品页数已超过 {{maxPageSize}} 页，系统将会为您自动分册
          <b-link v-b-modal="'divisionRules'">查看分册说明</b-link>
        </div>
      </b-card>

      <b-card class="card-price" header="价格详情">
        <b-row align-v="center">
          <b-col>
            <b>数量</b>
            <div class="small" v-if="price.comments && price.comments.count">
              <fa icon="gift" class="text-primary" fad/>
              {{price.comments.count}}
            </div>
          </b-col>
          <b-col cols="auto">
            <span v-if="price.disableMultiCount">×1</span>
            <stepper required v-model="order.count" style="max-width: 10em;" v-else/>
          </b-col>
        </b-row>

        <template v-if="price.details && price.details.length">
          <hr class="dashed">
          <div class="mb-2"><b>价格计算</b></div>
          <table class="table table-borderless table-sm price-detail">
            <tbody>
            <tr>
              <td>原价 <span v-if="price.rule">({{price.rule}})</span></td>
              <td>{{$rmb(price.oriPrice)}} × {{order.count}}</td>
            </tr>
            <tr v-for="item in price.details" :key="item.title">
              <td>{{item.title}}</td>
              <td>
                <template v-if="item.value">{{$rmb(item.value)}}</template>
                <template v-else-if="item.discount">× {{item.discount | couponValue}}</template>
              </td>
            </tr>
            </tbody>
          </table>
        </template>

        <hr class="dashed">

        <div>
          <b>订单总价</b>
          <b class="text-primary float-right" v-if="calculating">正在计算</b>
          <b class="text-primary float-right" v-else>{{$rmb(price.paidMoney)}}</b>
        </div>
      </b-card>

      <b-card class="card-coupons" header="折扣优惠" v-if="canUseCoupon">
        <template slot="header">
          折扣优惠
          <div class="float-right">
            <b-link @click="showRedeem = false" v-if="showRedeem">返回</b-link>
            <b-link @click="showRedeem = true" v-else-if="!coupons.length">使用优惠码</b-link>
            <b-link @click="showCoupons = true" v-else-if="!showCoupons">
              选择优惠券 ({{coupons.length}})
            </b-link>
            <b-link @click="showCoupons = false" v-else>返回</b-link>
          </div>
        </template>

        <template v-if="showRedeem">
          <p>在下方输入框中输入您得到的 6 位优惠码，点击使用即可</p>
          <b-input-group>
            <b-input :disabled="calculating || coupon.usable"
                     @input="coupon.status = 0"
                     class="text-uppercase"
                     maxlength="6" placeholder="输入 6 位优惠码"
                     type="search"
                     v-model="coupon.couponNo"/>
            <template slot="append">
              <b-btn @click="resetCoupon" v-if="coupon.status === 3">删除</b-btn>
              <b-btn :disabled="!coupon.couponNo || coupon.status === 1" @click="redeem" v-else>
                <fa icon="spinner" spin v-if="coupon.status === 1"/>
                使用
              </b-btn>
            </template>
          </b-input-group>

          <div class="mt-2 small text-success" v-if="coupon.status === 3">
            <fa icon="check"/>
            优惠码可用，已自动为您使用
          </div>

          <div class="mt-2 small text-danger" v-else-if="coupon.status === 2">
            <fa icon="times"/>
            优惠码无效，请检查后再试
          </div>
        </template>

        <template v-else>
          <template v-if="coupons && coupons.length">
            <div class="coupons" v-if="showCoupons">
              <cell group="coupon" reverse title="不使用优惠券" @click="showCoupons = false"
                    v-model="order.couponNo" val=""/>
              <cell :disabled="item.invalid" :key="item.couponNo" :val="item.couponNo"
                    group="coupon" reverse v-for="item in couponsSorted"
                    @click="showCoupons = false"
                    v-model="order.couponNo">
                <template slot="body">
                  <div class="coupon-name">
                    <fa class="text-warning" fas icon="star" v-if="item.isPublic"/>
                    <b v-if="item.extra && item.extra.cashback">返现优惠券</b>
                    <b v-else-if="item.name">{{item.name}}</b>
                    <span v-else>普通优惠券</span>
                  </div>
                  <template v-if="item.invalid">
                    <div class="small text-danger" v-if="item.minUsePrice > price.totalPrice">
                      满{{item.minUsePrice}}元可用
                    </div>
                    <div class="small text-danger">
                      <span class="limit" v-for="i in restrictions(item)" :key="i">{{i}}</span>
                    </div>
                  </template>
                  <div class="small text-muted">
                    <datetime :value="item.expireAt" suffix=" 过期"/>
                  </div>
                </template>
                <div class="text-right" slot="head">
                  <div class="coupon-value">
                    <span class="discount">{{item.value.slice(0, -1)}}</span>
                    {{item.value.slice(-1)}}
                  </div>
                </div>
              </cell>
              <div class="mt-2">
                <b-link @click="showRedeem = true">使用优惠码</b-link>
              </div>
            </div>
            <b-row align-v="center" @click="showCoupons = true" v-else>
              <b-col>
                <template v-if="couponSelected">
                  <div class="coupon-name">
                    <fa class="text-warning" fas fw icon="star" v-if="couponSelected.isPublic"/>
                    <fa class="text-success" fas fw icon="check" v-else/>
                    <b v-if="couponSelected.extra && couponSelected.extra.cashback">返现优惠券</b>
                    <b v-else-if="couponSelected.name">{{couponSelected.name}}</b>
                    <span v-else>普通优惠券</span>
                  </div>
                </template>
                <div class="text-muted" v-else>不使用优惠券</div>
              </b-col>
              <b-col cols="auto">
                <span class="text-danger" v-if="couponSelected">{{couponSelected.value}}</span>
              </b-col>
            </b-row>
          </template>
          <div class="text-muted" v-else>暂无可选优惠券</div>
        </template>
      </b-card>

      <b-card class="card-address" header="收件信息" v-if="price.lockEdit">
        <b-row>
          <b-col>
            <b-form-group label="收件人">{{price.consignee}}</b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="手机号">{{price.phone}}</b-form-group>
          </b-col>
        </b-row>

        <b-form-group class="mb-0" label="地址信息">
          {{price.addr.split('|').join(' ')}}
        </b-form-group>
      </b-card>

      <b-card class="card-address" v-else>
        <template slot="header">
          收件信息
          <b-link class="float-right" @click="showSplitter = !showSplitter">
            自动识别 (Beta)
            <fa icon="caret-up" v-if="showSplitter"/>
            <fa icon="caret-down" v-else/>
          </b-link>
        </template>

        <b-form-group v-if="showSplitter">
          <address-splitter @change="onDetect"></address-splitter>
        </b-form-group>

        <b-row>
          <b-col>
            <b-form-group label="收件人">
              <b-input id="consignee" maxlength="20" placeholder="收件人姓名" v-ls
                       v-model="order.consignee"/>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="手机号">
              <b-input id="phone" maxlength="11" placeholder="收件人手机号" type="tel" v-ls
                       v-model="order.phone"/>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group label="地址信息" v-if="!price.disableAddr">
          <address-picker cache v-model="order.address" ref="addressPicker"/>
        </b-form-group>

        <b-link @click="showNote = true" v-if="!showNote">
          <fa icon="plus-circle"/>
          添加留言
        </b-link>

        <b-form-group class="mb-0" v-else>
          <template slot="label">
            订单留言
            <b-link class="float-right" @click="order.note = ''; showNote = false">删除</b-link>
          </template>
          <textarea class="form-control" id="note" maxlength="100" name="note"
                    placeholder="是给客服看的留言哦" v-autofocus
                    v-autosize v-model="order.note" rows="1"></textarea>
        </b-form-group>
      </b-card>

      <b-card class="card-delivery" header="快递选择"
              v-if="choices.delivery && choices.delivery.length">
        <select-group :options="choices.delivery" v-model="order.delivery"/>
      </b-card>

      <b-card header="发票信息" v-if="canUseReceipt">
        <select-group :options="[{title: '无需发票', value: false}, {title: '电子发票', value: true}]"
                      v-model="enableReceipt"/>
        <receipt-input class="mt-3" v-if="enableReceipt" v-model="receipt" :phone="order.phone"/>
      </b-card>

      <b-card v-if="!isPartnerUser && price.paidMoney > 0" header="余额">
        <p>
          当前余额 <b>{{$rmb(userBalance)}}</b>
          <b-link @click.stop="goRecharge">充值更优惠</b-link>
        </p>
        <cell head-width="3em" title="余额支付" v-model="useBalance"
              type="checkbox" :disabled="!userBalance">
          <div slot="head">
            <img class="icon" src="../assets/images/icons/balance.svg">
          </div>
          <template slot="body">使用余额抵扣 {{$rmb(Math.min(userBalance, price.paidMoney))}}</template>
        </cell>

        <div class="mt-3" v-if="useBalance">
          <fa icon="info-circle" class="text-danger"/>
          使用余额支付时不支持自助开票哦，如需发票请联系客服
        </div>
      </b-card>

      <b-card v-if="need2Pay" header="支付方式">
        <cell head-width="3em" title="微信支付" v-model="order.payType" val="wxpay">
          <img class="icon" slot="head" src="../assets/images/icons/wxpay.svg">
        </cell>

        <cell head-width="3em" title="支付宝支付" v-if="!isMiniProgram" v-model="order.payType"
              val="alipay">
          <img class="icon" slot="head" src="../assets/images/icons/alipay.svg">
        </cell>

        <cell head-width="3em" title="请好友代付" v-model="order.payType" val="wxpay_web_friend">
          <img class="icon" slot="head"
               src="https://img.xinshu.me/upload/00bf4542454f4be184488ba809bb12eb">
        </cell>
      </b-card>

      <div class="btn-area">
        <b-button :disabled="creating" block type="submit" variant="primary">
          <fa fw icon="credit-card"/>
          <template v-if="creating">
            正在创建订单，请稍后...
          </template>
          <template v-else-if="order.payType === 'taobao'">
            下单并前往淘宝，仅需支付 {{$rmb(price.paidMoney * 0.95)}}
          </template>
          <template v-else-if="useBalance">
            <span v-if="shortage">支付剩余 {{$rmb(shortage)}}</span>
            <span v-else>直接下单</span>
          </template>
          <template v-else-if="!need2Pay">
            无需支付，直接下单
          </template>
          <template v-else>
            支付 {{$rmb(price.paidMoney)}}
          </template>
        </b-button>
      </div>

      <div class="text-center" v-if="!isMiniProgram">
        <qiyu variant="link">联系客服</qiyu>
      </div>

      <b-modal hide-footer id="typesetDetail" title="装帧介绍">
        <b-tabs fill>
          <b-tab :title="k" v-for="(v, k) in typesetIntros" :key="k">
            <div class="image mt-3">
              <img :src="v" height="auto"/>
            </div>
          </b-tab>
        </b-tabs>
      </b-modal>

      <b-modal hide-footer id="calendarMetalPics" title="装帧介绍">
        <img src="https://img.xinshu.me/upload/3d0d04b1085c0aa3b64d9a10b302dbab" alt="礼盒装">
      </b-modal>

      <b-modal hide-footer id="divisionRules" title="分册说明">
        <img alt="分册说明图片"
             src="https://img.xinshu.me/upload/c3045c4527328079cdd03c41e0c4b813">
      </b-modal>
    </form>
  </div>
</template>

<script>
import { chain, find, forEach, includes, round, sample } from 'lodash'

import AddressPicker from '@/components/AddressPicker'
import ReceiptInput from '@/components/ReceiptInput'
import Stepper from '@/components/Stepper'

import bookUtils from '@/mixins/book-utils'
import routeData from '@/mixins/route-data'

import Qiyu from '@/components/Qiyu'
import AddressSplitter from '@/components/AddressSplitter'

import { ss } from '@/plugins/storage'

export default {
  name: 'buy',
  title: '下单购买',
  mixins: [bookUtils, routeData('book'), routeData('coupons')],
  components: {AddressSplitter, Qiyu, Stepper, AddressPicker, ReceiptInput},
  beforeRouteEnter(to, from, next) {
    const key = 'checked.' + to.params.bookId
    if (!ss.getItem(key) && !to.query.force) {
      return next({
        name: 'check',
        params: to.params,
        query: to.query
      })
    }

    ss.removeItem(key)

    if (ss.getItem('source') === 'agent') {
      return next(to.path + '/agent')
    }

    next()
  },
  data() {
    return {
      couponLimit: 10,

      userBalance: 0,
      useBalance: false,

      price: {},

      order: {
        count: 1,
        binding: '',
        delivery: '',
        couponNo: '',

        consignee: '',
        phone: '',
        address: '',

        payType: 'wxpay'
      },

      enableReceipt: false,
      receipt: {
        title: '',
        type: 0,
        category: '技术服务费',
        taxNumber: '',
        phone: '',
        email: ''
      },

      choices: {},
      taobao: {},
      coupon: {
        couponNo: '',
        status: 0
      },

      taobaoStatus: -1,
      creating: false,
      calculating: false,
      showNote: false,
      showRedeem: false,
      showCoupons: false,
      showSplitter: false
    }
  },
  created() {
    if (!this.isPartnerUser) {
      this.$ajax.fetchBalance().then(data => {
        this.userBalance = data.balance
        if (this.userBalance > 0) {
          this.useBalance = true
        }
      })
    }
    this.$ss.removeItem('afterRecharge')
  },
  computed: {
    typesetIntros() {
      /* eslint-disable quote-props */
      if (/A4/.test(this.book.type)) {
        return {
          '软装': 'https://img.xinshu.me/resource/f9f4c1edf6524a5193f140507ae123ef',
          '精装': 'https://img.xinshu.me/upload/a7181f4c3b904b8696df9cd9fe160867'
        }
      }
      if (/A5/.test(this.book.type)) {
        return {
          '经济装': 'https://img.xinshu.me/upload/5d529d0c89384cdc8371c53fa90f37b0',
          '文艺装': 'https://img.xinshu.me/upload/495ac3517d4e4a829572692b8a87419c',
          '精装': 'https://img.xinshu.me/upload/a7181f4c3b904b8696df9cd9fe160867'
        }
      }
      return null
    },
    maxPageSize() {
      if (this.order.binding === '2') {
        return 200
      }
      return 240
    },
    enablePdf() {
      return this.isPdf || this.$route.query.pdf || this.price?.chinaMainlandIp === false
    },
    isPdf() {
      return this.order.binding === 'pdf'
    },
    isRedeemed() {
      return this.book?.redeemCode
    },
    couponSelected() {
      if (!this.coupons?.length) {
        return null
      }
      return this.coupons.find(i => i.couponNo === this.order.couponNo)
    },
    tab: {
      get() {
        if (this.book.type !== 'A4') {
          return Number(this.order.binding)
        }
        return 0
      },
      set() {
      }
    },
    couponsSorted() {
      return chain(this.coupons)
        .map(i => {
          i.invalid = !this.usable(i)
          return i
        })
        .orderBy('invalid')
        .slice(0, this.couponLimit).value()
    },
    canUseReceipt() {
      return !this.useBalance && this.price.paidMoney > 10 && !this.isWorkshopUser
    },
    canUseCoupon() {
      return !this.isPartnerUser && this.price.totalPrice && !this.isRedeemed
    },
    priceRelative() {
      return [
        this.order.count,
        this.order.binding,
        this.order.address,
        this.order.couponNo,
        this.order.delivery
      ].join(',')
    },
    cacheParams() {
      return [
        this.order.consignee,
        this.order.phone,
        this.order.address
      ].join(',')
    },
    errors() {
      const errors = []
      const {consignee, phone, address, binding, count} = this.order

      if (!consignee) {
        errors.push({
          message: '请补全收货人信息',
          type: 'consignee'
        })
      }

      if (/test|测试|^\d+$/.test(consignee)) {
        errors.push({
          message: '请填写正确的收货人信息',
          type: 'consignee'
        })
      }

      if (!phone) {
        errors.push({
          message: '请补全手机号',
          type: 'phone'
        })
      }

      if (!/1[3456789]\d{4}\d{4}/.test(phone)) {
        errors.push({
          message: '手机号格式错误',
          type: 'phone'
        })
      }

      if (binding !== 'pdf') {
        if (!address || address.split('|').filter(Boolean).length < 4) {
          errors.push({
            message: '请补全地址信息',
            type: 'address'
          })
        }
      }

      if (!count) {
        errors.push({
          message: '请选择数量',
          type: 'count'
        })
      }

      if (this.enableReceipt && this.receipt.errors.length) {
        errors.push(...this.receipt.errors)
      }

      return errors
    },
    isSample() {
      return /^TY/i.test(this.order.binding)
    },
    bindings() {
      return this.choices.binding
    },
    need2Pay() {
      if (this.useBalance) {
        return this.shortage > 0
      }
      return this.price.paidMoney > 0
    },
    shortage() {
      return round(Math.max(0, this.price.paidMoney - this.userBalance), 2)
    }
  },
  watch: {
    'order.binding'() {
      this.autoSelectCoupon(true)
      this.order.delivery = ''
    },
    priceRelative: {
      handler: 'calculatePrice',
      deep: true
    }
  },
  methods: {
    async onLoad() {
      try {
        const {address, phone, consignee} = this.order
        if (!address || !phone || !consignee) {
          await this.fetchUserAddress()
        }
        const data = await this.calculatePrice()
        if (data.lockEdit) {
          this.order.consignee = data.consignee
          this.order.phone = data.phone
          this.order.address = data.addr
        }
        const {choices, defaults} = data
        if (choices) {
          // 使用接口的数据重置默认值
          for (const key of Object.keys(choices)) {
            const values = choices[key].map(i => i.value)
            if (values.includes(defaults[key])) {
              this.order[key] = defaults[key]
            } else {
              this.order[key] = values[0]
            }
          }
        }
      } finally {
        if (this.canUseCoupon) {
          this.autoSelectCoupon()
        }

        this.$gtag.event('begin_checkout', {
          items: [
            {
              id: [this.book.bookType + this.book.id],
              name: this.book.title || this.book.name,
              category: this.book.bookType,
              quantity: 1,
              price: this.price.paidMoney
            }
          ],
          coupon: ''
        })
      }
    },
    onDetect({consignee, phone, address}) {
      this.order.consignee = consignee
      this.order.phone = phone
      this.order.address = address
      if (!address) {
        this.$refs.addressPicker.reset()
      }
    },
    async onSubmit() {
      try {
        if (this.errors.length) {
          const error = this.errors[0]
          this.$alert.error(error.message)
          if (!window.isMobile) {
            const errorEl = this.$el.querySelector('#' + error.type)
            if (errorEl) {
              errorEl.scrollIntoView()
              errorEl.focus()
            }
          }
          return
        }

        const order = this.order

        this.$ls.set('consignee', order.consignee)
        this.$ls.set('phone', order.phone)

        const orderData = {
          count: order.count,
          binding: order.binding,

          bookId: this.book.id,
          bookType: this.book.bookType || this.book.tid,

          // 收货人信息
          consignee: order.consignee,
          phone: order.phone,
          addr: order.address,
          note: order.note,

          // 价格相关
          couponNo: order.couponNo,
          shipCompany: order.delivery,
          payType: order.payType || 'wxpay'
        }

        if (orderData.binding === 'pdf') {
          orderData.addr = '电子版无须填写地址'
        }

        if (this.useBalance) {
          orderData.payType = 'balance'
        }

        if (this.canUseReceipt && this.enableReceipt) {
          orderData.invoice = {
            type: 0,
            category: '技术服务费',
            title: this.receipt.title,
            taxNumber: this.receipt.taxNumber,
            phone: this.receipt.phone,
            email: this.receipt.email
          }
        }

        if (this.price.lockEdit) {
          orderData.consignee = this.price.consignee
          orderData.phone = this.price.phone
          orderData.addr = this.price.addr
        }

        if (this.isDev) {
          const testNotes = [
            '我有一头小毛驴我从来也不骑',
            '有一天我心血来潮骑它去赶集',
            '我手里拿着小皮鞭我心里正得意',
            '不知怎么哗啦啦啦摔了我一身泥'
          ]
          orderData.note = orderData.note || sample(testNotes)
        }

        this.creating = true
        const result = await this.createOrder(orderData)
        const orderNo = result.id || result.orderId || result.orderNo

        if (result.paidMoney === 0) {
          this.$alert.success('下单成功，无需支付')
          this.$router.push('/orders/' + orderNo + '/result')
          return
        }

        this.$alert.success('下单成功，请继续支付')

        if (this.useBalance && this.shortage) {
          const rechargeOrder = await this.$req.post('/api/gift_card/order/create', {
            cardId: 0,
            payType: this.order.payType,
            price: this.shortage,
            relateOrderNo: orderNo
          })
          this.$router.push('/pay/' + rechargeOrder.orderNo)
          return
        }

        if (this.order.payType === 'taobao') {
          return this.$router.replace('/pay/' + orderNo + '/taobao')
        }

        this.$router.push('/pay/' + orderNo)
      } finally {
        this.creating = false
      }
    },
    createOrder(order) {
      if (this.book.tid || this.book.bookType === 'blogbook') {
        return this.$req.post('/api/order/albums/orders', order)
      }
      return this.$req.post('/api/order/orders', order)
    },

    restrictions(coupon) {
      const result = []
      const type = this.book.type
      const product = this.book.tid || this.book.bookType
      const binding = this.order.binding
      if (coupon.binding.length && !includes(coupon.binding, binding)) {
        // 设置了装帧限制
        result.push(this.$options.filters.showBindings(coupon.binding))
      }
      if (coupon.products.length && !includes(coupon.products, product)) {
        // 设置了产品限制
        result.push(this.$options.filters.showProducts(coupon.products))
      }
      if (coupon.publishTypes.length && !includes(coupon.publishTypes, type)) {
        // 设置了排版限制
        result.push(this.$options.filters.showPublishTypes(coupon.publishTypes))
      }
      return result
    },
    usable(coupon) {
      const result = [true]
      const type = this.book.type
      const product = this.book.tid || this.book.bookType
      const binding = this.order.binding
      if (coupon.minUsePrice) {
        // 设置了最低使用金额
        result.push(this.price.totalPrice >= coupon.minUsePrice)
      }
      if (coupon.binding && coupon.binding.length) {
        // 设置了装帧限制
        result.push(coupon.binding.indexOf(binding) > -1)
      }
      if (coupon.products && coupon.products.length) {
        // 设置了产品限制
        result.push(coupon.products.indexOf(product) > -1)
      }
      if (coupon.publishTypes && coupon.publishTypes.length) {
        // 设置了排版限制
        result.push(coupon.publishTypes.indexOf(type) > -1)
      }
      return result.every(Boolean)
    },

    calculatePrice() {
      return new Promise((resolve, reject) => {
        clearTimeout(this.calculate)
        this.calculating = true
        this.calculate = setTimeout(async () => {
          try {
            const params = {...this.order, bookId: this.bookId}
            const data = await this.$ajax.fetchPrice(params)
            this.price = data
            this.choices = data.choices

            if (data.choices) {
              // 用来渲染装帧、快递等可选项
              if (data.choices.binding && !this.enablePdf) {
                data.choices.binding = data.choices.binding.filter(i => i.value !== 'pdf')
              }

              forEach(data.choices, (val, key) => {
                // 如果 choice 中不包含当前选择的项，则使用 defaults 中提供的默认选项
                // 如用户初始选择的是圆通
                // 修改地址后无法选择圆通，就改为返回的默认值顺丰
                if (!find(val, {value: this.order[key]})) {
                  this.order[key] = data.defaults[key]
                }
              })
            }

            this.coupon.usable = data.couponNo === this.coupon.couponNo && !!this.coupon.couponNo
            if (this.coupon.couponNo) {
              if (this.order.couponNo !== this.coupon.couponNo) {
                this.coupon.status = 0
              } else {
                this.coupon.status = data.couponNo ? 3 : 2
              }
            }
            if (!data.couponNo) {
              this.order.couponNo = ''
            }
            if (this.useBalance && data.paidMoney > this.userBalance) {
              // 勾选余额后余额不足时自动选择微信支付
              this.order.payType = 'wxpay'
            }
            return resolve(data)
          } catch (err) {
            reject(err)
          } finally {
            this.calculating = false
          }
        }, 200)
      })
    },

    redeem() {
      this.coupon.status = 1
      this.order.couponNo = this.coupon.couponNo
    },
    resetCoupon() {
      this.order.couponNo = ''
      this.coupon.couponNo = ''
      this.coupon.status = 0
    },
    autoSelectCoupon(force) {
      const usableCoupon = chain(this.coupons)
        .filter(this.usable)
        .filter(item => {
          return item.price < this.price.oriPrice
        })
        // 实际折扣最多的排最前面
        .orderBy(coupon => {
          if (coupon.price >= 1) {
            return coupon.price
          }
          return this.price.oriPrice * (1 - coupon.price)
        }, 'desc')
        .first()
        .value()

      if (usableCoupon && (force || !this.order.couponNo)) {
        this.order.couponNo = usableCoupon.couponNo
      }
    },
    goRecharge() {
      this.$ss.setItem('afterRecharge', this.bookId)
      this.$router.push('/my/recharge')
    },

    async fetchUserAddress() {
      const result = await this.$req.get('/api/order/address')
      this.order.consignee = this.order.consignee || result.name
      this.order.phone = this.order.phone || result.phone
      if (result.addr && !/电子版/.test(result.addr)) {
        this.order.address = this.order.address || result.addr
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.purchase {
  max-width: 560px;

  .book-title {
    font-size: 1.25em;
    margin-bottom: .5rem;
  }

  .card-header {
    background-color: #fff;
    border-bottom: 0;
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 0;
    padding-bottom: 0;

    .float-right {
      font-size: .8em;
      font-weight: normal;
      margin-top: 3px;
    }
  }

  .card {
    margin-bottom: $grid-gutter-width-base / 2;
  }

  .btn-select {
    margin-right: .5em;
  }

  .coupons {
    .cell {
      margin: 0;
      padding: .5rem 0;
      border-top: 1px dashed $hr-border-color;
    }

    .limit + .limit {
      &:before {
        content: '；';
      }
    }

    .coupon-value {
      color: $primary;
      font-size: 12px;
      line-height: 1.2;
    }

    .discount {
      font-size: 2rem;
      margin-right: -2px;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .icon {
    width: 2em;
    height: auto;
    vertical-align: middle;
  }

  .btn-area {
    margin-top: 1.5rem;

    .btn-block {
      padding-top: 1em;
      padding-bottom: 1em;
    }
  }

  ::v-deep #typesetDetail {
    .nav-pills {
      flex-direction: row;
    }

    .nav-item {
      flex: 1;
      text-align: center;
      width: 100%;
    }
  }
}

.price-detail {
  td:last-child {
    text-align: right;
  }

  td {
    padding: 2px 0;
  }
}
</style>
