<template>
  <div class="receipt-input">
    <slot name="title"></slot>

    <div class="mb-2">
      如需开具<b>增值税专用发票</b>，除此处信息外请在留言中提供其他开票信息。
    </div>

    <b-row>
      <b-col sm>
        <b-form-group label="发票抬头">
          <b-input id="receiptTitle" maxlength="64" v-model.trim="receipt.title" placeholder="单位全称"/>
        </b-form-group>
      </b-col>
      <b-col sm>
        <b-form-group>
          <template slot="label">
            纳税人识别号
            <small class="text-muted" v-if="receipt.taxNumber && receipt.taxNumber.length">
              (当前 {{receipt.taxNumber.length}} 位)
            </small>
          </template>
          <b-input id="receiptTaxNumber" v-model.trim="receipt.taxNumber" class="text-uppercase"
                   placeholder="15、18或20位" maxlength="21"/>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mt-0">
      <b-col sm>
        <b-form-group>
          <template slot="label">
            手机号
            <b-check v-model="lock" class="float-right" @change="onLocked">使用收件人手机号</b-check>
          </template>
          <b-input id="receiptPhone" type="tel" maxlength="11" :readonly="lock"
                   placeholder="收票手机号" v-model="receipt.phone"/>
        </b-form-group>
      </b-col>
      <b-col sm>
        <b-form-group label="电子邮箱地址">
          <b-input id="receiptEmail" type="email" maxlength="40" required
                   placeholder="收票邮箱" v-model="receipt.email"/>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { isEmpty, pick, uniqBy } from 'lodash'

export default {
  name: 'receiptInput',
  props: {
    value: Object,
    phone: String
  },
  data() {
    return {
      lock: false,
      receipt: {
        title: '',
        taxNumber: '',
        phone: '',
        email: ''
      }
    }
  },
  computed: {
    errors() {
      const errors = []
      const {title, taxNumber, phone, email} = this.receipt
      if (!title) {
        errors.push({
          message: '请填写发票抬头',
          type: 'receiptTitle'
        })
      }
      if (title.length < 4 || /^\w+$/.test(title)) {
        errors.push({
          message: '请填写正确的发票抬头',
          type: 'receiptTitle'
        })
      }
      if (!taxNumber) {
        errors.push({
          message: '请填写发票税号',
          type: 'receiptTaxNumber'
        })
      }
      if (taxNumber.length < 15) {
        errors.push({
          message: '请填写正确的发票税号',
          type: 'receiptTaxNumber'
        })
      }
      if (!phone) {
        errors.push({
          message: '请填写发票收取手机号',
          type: 'receiptPhone'
        })
      }
      if (!email) {
        errors.push({
          message: '请填写发票收取邮箱',
          type: 'receiptEmail'
        })
      }
      if (email && !/^.+@.+\.\w+$/.test(email)) {
        errors.push({
          message: '发票收取邮箱格式错误',
          type: 'receiptEmail'
        })
      }
      return uniqBy(errors, 'message')
    }
  },
  created() {
    if (!isEmpty(this.value)) {
      this.receipt.title = this.value.title
      this.receipt.taxNumber = this.value.taxNumber
      this.receipt.phone = this.value.phone
      this.receipt.email = this.value.email
    }
    const cache = this.$ls.get('receipt')
    if (!isEmpty(cache)) {
      this.receipt = Object.assign({}, this.receipt, cache)
    }
    if (this.receipt.phone === this.phone) {
      this.lock = true
    }
    this.$forceUpdate()
  },
  watch: {
    phone(val) {
      if (this.lock) {
        this.receipt.phone = val
      }
    },
    receipt: {
      handler(val) {
        val.taxNumber = val.taxNumber.replace(/\W/g, '')
        this.$emit('input', {...val, type: 0, category: '技术服务费', errors: this.errors})
        this.$ls.set('receipt', pick(val, ['title', 'taxNumber', 'phone', 'email']))
        if (!val.title && !val.taxNumber) {
          this.$ls.remove('receipt')
        }
      },
      deep: true
    }
  },
  methods: {
    onLocked(val) {
      if (val) {
        this.receipt.phone = this.phone
      } else if (this.receipt.phone === this.phone) {
        this.receipt.phone = ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .receipt-input {
  }
</style>
