<template>
  <div class="order-result">
    <loading v-if="loading"/>
    <template v-else>
      <template v-if="checking">
        <b-card class="text-center">
          <p>
            <fa icon="cog" fad palse class="text-primary" spin size="5x"/>
          </p>
          <h3>正在查询支付结果</h3>
          <div>请稍候...</div>
        </b-card>
      </template>

      <template v-else-if="order.paid">
        <b-card>
          <div class="text-center">
            <p>
              <fa icon="check-circle" class="text-success" size="5x"/>
            </p>
            <h2 class="title">支付成功</h2>
          </div>
          <table class="table border-less mb-0">
            <tbody>
            <tr>
              <td style="width: 6em;">订单内容</td>
              <td>{{order.name || order.bookName}}</td>
            </tr>
            <tr>
              <td>价格数量</td>
              <td>{{$rmb(order.paidMoney)}} / {{order.count}}</td>
            </tr>
            <tr v-if="order.book">
              <td>版式装帧</td>
              <td>
                {{order.book.typesetName}}
                <template v-if="order.bindingName">/ {{order.bindingName}}</template>
              </td>
            </tr>
            <tr v-if="order.addr">
              <td>收货信息</td>
              <td>
                {{order.consignee}} {{order.phone}}
                <br>
                {{address}}
              </td>
            </tr>
            </tbody>
          </table>
        </b-card>

        <template v-if="bookType === 'star-wbbook'">
          <div class="btn-area">
            <b-btn block variant="primary" href="https://weiboshu.com/my/orders" target="_top">
              查看订单
            </b-btn>
            <b-btn block variant="link" href="https://weiboshu.com/my/books" target="_top">
              返回书架
            </b-btn>
          </div>
        </template>

        <template v-else-if="!isAgentUser">
          <div class="btn-area">
            <b-btn block variant="primary" :to="'/orders/' + order.id" exact>
              <template v-if="isGiftCards">查看套装订单</template>
              <template v-else-if="isPackage">查看套装内兑换码</template>
              <template v-else>查看订单详情</template>
            </b-btn>
            <b-btn block variant="link" :to="'/books?bookType=' + bookType">返回书架</b-btn>
          </div>
          <feedback no-contact/>
        </template>
      </template>

      <template v-else>
        <b-card>
          <div class="text-center">
            <p>
              <fa icon="times-circle" class="text-danger" size="5x"/>
            </p>
            <h2 class="title">订单支付未完成</h2>
          </div>
          <p class="text-center">
            您可以 <b-link @click="initOrder">点此重新查询订单状态</b-link>
            <br>
            或点击下方按钮联系客服
          </p>
          <table class="table border-less mb-0">
            <tbody>
            <tr>
              <td>订单号</td>
              <td>{{order.id}}</td>
            </tr>
            <tr>
              <td style="width: 6em;">作品名称</td>
              <td>{{order.book.title}}</td>
            </tr>
            <tr>
              <td>价格数量</td>
              <td>{{$rmb(order.paidMoney)}} / {{order.count}}</td>
            </tr>
            <tr>
              <td>版式装帧</td>
              <td>
                {{order.book.typesetName}}
                /
                <binding :value="order"/>
              </td>
            </tr>
            <tr>
              <td>收货信息</td>
              <td>
                {{order.consignee}} {{order.phone}} <br>
                {{address}}
              </td>
            </tr>
            </tbody>
          </table>
        </b-card>
        <div class="btn-area">
          <qiyu class="btn-block"/>
          <b-btn block @click="$router.go(-1)">返回</b-btn>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import routeData from '@/mixins/route-data'
import Feedback from './Feedback.vue'
import Qiyu from '@/components/Qiyu'
import { isAlbum } from '@/models/book'
import { wait } from '@/utils/wait-for'

export default {
  name: 'orderResult',
  title: '支付结果',
  mixins: [routeData('order')],
  components: {Qiyu, Feedback},
  beforeRouteEnter(to, from, next) {
    const orderNo = to.params.orderNo
    const redirect = localStorage.getItem('order.redirect.' + orderNo)
    if (redirect) {
      return location.replace(redirect)
    }
    if (/^giftcard/.test(orderNo) || /^2\d{14}$/.test(orderNo)) {
      // 充值订单跳转到充值订单专用结果页面
      next(`/my/recharge-orders/${orderNo.replace(/[^\d]/g, '')}/result`)
      return
    }
    next()
  },
  data() {
    return {
      checking: false
    }
  },
  computed: {
    address() {
      if (!this.order?.addr) {
        return ''
      }
      return this.order.addr.split('|').join(' ')
    },
    bookType() {
      if (isAlbum(this.order.bookType)) {
        return 'album'
      }
      return this.order.bookType
    },
    isGiftCards() {
      return this.order?.package?.category === 'physical'
    },
    isPackage() {
      return /^5\d{14}/.test(this.order.id)
    }
  },
  methods: {
    async onLoad() {
      if (this.order.relateOrderNo) {
        return this.$router.replace('/pay/' + this.order.relateOrderNo)
      }

      if (!this.order.paid) {
        try {
          let count = 0
          this.checking = true
          do {
            if (!this.checking) {
              break
            }
            await this.updateOrder()
            await wait(2000)
            count++
          } while (count < 30 && !this.order.paid)
        } finally {
          this.checking = false
        }
      }

      this.$nextTick(() => {
        if (this.order.paidType === 'balance') {
          // 余额支付不计算
          return
        }
        this.sendGAData(this.order)
      })
    },
    sendGAData(order) {
      this.$gtag.purchase({
        transaction_id: order.id,
        affiliation: order.paidType,
        value: order.price,
        currency: 'CNY',
        items: [{
          id: order.id,
          name: order.bookName,
          category: order.bookType,
          sku: [order.bookType, order.printType || '', order.binding || ''].filter(Boolean).join('_'),
          price: order.price / order.count,
          quantity: order.count || 1,
          coupon: order.couponNo
        }]
      })
    }
  }
}
</script>

<style scoped lang="scss">
.order-result {
  max-width: 480px;

  .title {
    margin-bottom: .75em;
  }

  .qrcode-image {
    width: 160px;
    margin-left: auto;
    margin-right: auto;

    img {
      width: 100%;
    }
  }
}
</style>
