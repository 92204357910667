<template>
  <div class="check">
    <h2 class="page-title">检查作品</h2>
    <loading text="正在为您检查作品" v-if="loading"/>
    <template v-else>
      <template v-if="needRefresh">
        <b-card class="text-center">
          <form @submit.prevent="redeemBuy(true)">
            <div class="mb-4">
              <fa icon="exclamation-circle" size="5x" class="text-primary"/>
            </div>
            <h4 class="mb-3">{{errors[0].title}}</h4>
            <div class="mb-3">{{errors[0].message}}</div>
            <b-form-group>
              <b-input v-model="redeemCode" placeholder="请输入兑换码" maxlength="8"
                       style="text-transform: uppercase"/>
            </b-form-group>
            <b-btn block variant="primary" type="submit"
                   :disabled="!redeemCode || redeemCode.length < 8 || submitting">
              兑换并继续
            </b-btn>
          </form>
          <b-btn class="mt-2" block @click="redeemBuy(false)" :disabled="submitting">付费购买</b-btn>
        </b-card>

        <div class="btn-area">
          <b-btn @click="$router.go(-1)" exact block>返回</b-btn>
        </div>
      </template>

      <template v-else-if="errors.length">
        <b-card class="text-center">
          <fa icon="exclamation-circle" size="5x" class="text-primary"/>
          <h4 class="mt-2 mb-3">{{errors[0].title}}</h4>
          <div class="alert alert-secondary">
            <div :key="index" v-for="(item, index) in errors">{{item.message}}</div>
          </div>
        </b-card>

        <div class="btn-area">
          <template v-if="$store.state.prevRoute">
            <b-btn variant="primary" :to="'/books/' + bookId" exact block>前往修改</b-btn>
            <b-btn @click="$router.go(-1)" exact block>返回</b-btn>
          </template>

          <template v-else-if="errors[0].refresh">
            <b-btn variant="primary" @click="initBook()" block>刷新看看</b-btn>
            <b-btn @click="$router.go(-1)" exact block>返回</b-btn>
          </template>

          <b-btn v-else :to="'/books/' + bookId" exact block>返回作品</b-btn>
        </div>
      </template>

      <b-card v-else-if="warnings.length">
        <p>系统检查到您的作品有以下 <b>{{warnings.length}}</b> 个问题，可能会<b>影响印刷质量</b></p>

        <div class="problem" :key="'warning' + index" v-for="(item, index) in warnings">
          <b-row align-v="center">
            <b-col cols="auto" style="min-width: 8rem;">
              <square :src="item.url" border mode="contain" v-if="item.url"/>
            </b-col>
            <b-col class="pl-0" style="min-width: 0;">
              <div>
                <span v-if="item.index >= 0">
                  位于第 {{item.index * step + 1}} 页
                </span>
                <span v-else>位于封面</span>
              </div>
              问题：<b>{{item.title}}</b>
              <div>说明：{{item.message}}</div>
              <div>
                建议：<span v-html="item.suggestion"></span>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>

      <b-card v-if="!book.isAlbum && book.bookType !== 'blogbook' && !errors.length">
        <loading v-if="!months" text="正在检查月份"/>
        <template v-else>
          <div class="mb-1">您当前作品的起止时间为</div>
          <h3 class="py-3 text-center">
            {{months.firstValid().value}}
            <small>~</small>
            {{months.lastValid().value}}
          </h3>
          <div>总计 {{months.length}} 个月份，约 {{book.pages}} 页，以下为已选的月份</div>
          <div class="py-3">
            <div class="mb-2" v-for="(group, year) in months" :key="year">
              <div class="clearfix">
                <b>{{year}}年</b>
                <span>(共 {{group.length}} 个月)</span>
              </div>
              <grid :cols="isSmallScreen ? 8 : 12">
                <grid-item v-for="i in group" :key="i.value">
                  <square class="month">{{i.month}}</square>
                </grid-item>
              </grid>
            </div>
          </div>
          <div v-if="book.finalized === false">
            如果您需要修改月份，请前往
            <b-link to="./months">筛选月份</b-link>
          </div>
        </template>
      </b-card>

      <template v-if="errors.length === 0 || $route.query.force">
        <b-card>
          <b-card-title>
            温馨提示
            <fa icon="heart" :fas="enablePdf" class="text-primary"
                @click="enablePdf = !enablePdf"/>
          </b-card-title>

          <template v-if="!orderTips.length">
            <p>
              <ske block/>
            </p>
            <p>
              <ske block/>
            </p>
            <ske block/>
          </template>

          <ol class="mb-0 pl-4" v-else>
            <li :key="item.content" class="mt-2"
                v-for="item in orderTips" v-html="item.content"></li>
          </ol>
        </b-card>

        <div class="btn-area" v-if="issues.length">
          <b-btn @click="goPay(false, $event)" block>忽略并继续下单</b-btn>
          <b-btn :to="'/books/' + bookId" exact block variant="primary">返回修改</b-btn>
        </div>

        <div class="btn-area" v-else>
          <b-btn @click="goPay(true, $event)" block variant="primary">
            <fa icon="shopping-cart"/>
            前往下单
          </b-btn>
          <b-btn :to="'/books/' + bookId" exact variant="link" block>返回作品</b-btn>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'

import bookUtils from '@/mixins/book-utils'
import routeData from '@/mixins/route-data'

import { bookProducts } from '@/config'
import getLines from '@/utils/get-lines'

export default {
  name: 'check',
  title: '检查作品',
  mixins: [routeData('book'), bookUtils],
  data() {
    return {
      countDown: 2,
      months: null,
      submitting: false,
      forceValidate: !!this.$route.query.validate,
      redeemCode: '',
      issues: [],
      orderTips: [],
      enablePdf: !!this.$route.query.pdf
    }
  },
  computed: {
    step() {
      return ['xalbum', 'album', 'picturebook', 'square-album'].includes(this.book.tid) ? 2 : 1
    },
    tipCategory() {
      return this.userSource || this.book.tid || this.book.bookType
    },
    bookId() {
      return this.$route.params.bookId
    },
    warnings() {
      return this.issues.filter(item => item.level === 'warning')
    },
    errors: {
      get() {
        const errors = this.issues.filter(item => item.level === 'error')
        if (errors.some(e => e.top)) {
          return errors.filter(e => e.top)
        }
        return errors
      },
      set(val) {
        if (!val || !val.length) {
          this.issues = this.issues.filter(item => item.level !== 'error')
        }
      }
    },
    needRefresh() {
      return !!this.errors.find(i => i.type === 'redeem')
    }
  },
  methods: {
    async onLoad() {
      try {
        this.loadStatus++

        if (this.book.months) {
          this.months = this.book.months
        } else if (this.book.fromDate) {
          this.months = await this.$ajax.fetchMonths(this.$route.params)
        }

        if (!this.forceValidate) {
          await this.checkSale()
          await this.checkInviter()
        }
        await this.checkCoverDeprecation()
        await this.checkCoverPic()
        if (!this.forceValidate) {
          await this.checkUsage()
        }
        await this.checkTypeset()
        await this.checkPages()
      } catch (err) {
        this.issues.push({
          level: 'error',
          title: '作品检查失败',
          message: err.message
        })
        console.error(err)
      } finally {
        this.initOrderTips()
        this.loadStatus--
      }
    },
    async initOrderTips() {
      const result = await this.$ajax.fetchOrderTips()

      this.orderTips = result.filter(i => i.category === this.tipCategory)

      if (!this.orderTips.length) {
        // 回退至照片书默认提示
        if (!Object.keys(bookProducts).includes(this.tipCategory)) {
          this.orderTips = result.filter(i => i.category === 'albums')
        }
      }

      // 回退至默认提示
      if (!this.orderTips.length) {
        this.orderTips = result.filter(i => !i.category)
      }
    },
    async checkSale() {
      const offline = {
        'calendar-h': '2019-04-01',
        'calendar-v': '2019-04-01',
        'calendar-metal': '2019-12-31',
        storybook: '2019-02-01',
        calendar: '2020-04-01',
        calbum: '2020-06-10',
        'calendar-2021': '2021-04-30'
      }
      const dueTime = offline[this.book.tid]
      if (this.$day().isAfter(dueTime)) {
        this.issues.push({
          title: '很抱歉，产品已下架',
          message: '当前产品已于' + this.$day(dueTime).format('YYYY年M月D日') + '下架了哦，试试其他新款吧',
          level: 'error',
          type: 'offline',
          top: true
        })
      }
    },
    async checkInviter() {
      if (!/wm-/.test(this.book.tid)) {
        return
      }
      const result = await this.$req.get('/api/weimeng/agents/inviter')
      if (isEmpty(result)) {
        this.issues.push({
          title: '很抱歉，您暂时无法购买照片书',
          message: '请联系您的经销商索取制作二位码，扫码后方可下单制作',
          level: 'error',
          type: 'inviter',
          top: true
        })
      }
    },
    async checkUsage() {
      if (!this.book.redeemCode) {
        return
      }

      const result = await this.$req.post('/api/redeem_code/validate', {
        redeemCode: this.book.redeemCode,
        tid: this.book.tid
      }, {fallThrough: true})

      if (result.errcode === 20705) {
        this.issues.push({
          title: '当前作品已下过订单',
          message: '加印需消耗一个新的兑换码，当前作品将会被复制一本以供您下单',
          level: 'error',
          type: 'redeem',
          top: true
        })
      }
    },
    async checkCoverDeprecation() {
      if (this.book.isAlbum) {
        // 照片书不检查
        return
      }
      const results = await this.$ajax.fetchCoverTemplates()
      const codeNames = results.filter(i => {
        if (this.book.isAlbum) {
          return i.type === 'album'
        }
        if (this.book.bookType === 'blogbook') {
          return i.type === 'blogbook'
        }
        return i.type === 'book'
      }).map(i => i.codeName.toLowerCase())
      const {codeName} = this.book.cover
      if (!codeNames.includes(codeName.toLowerCase())) {
        this.issues.push({
          level: 'warning',
          title: '封面已下架',
          message: '您选择了已下架的封面，印刷可能会有裁切或偏色问题',
          suggestion: '更换封面样式',
          url: this.coverUrl
        })
      }
    },
    async checkCoverPic() {
      let {pic, codeName} = this.book.cover
      if (this.book.tid === 'note' && !pic) {
        this.issues.push({
          level: 'warning',
          title: '未上传图片',
          message: '您没有上传图片，将会使用默认图片进行印刷哦',
          suggestion: '上传自定义图片',
          url: this.getCoverUrl(this.book)
        })
        return
      }

      if (codeName === 'cover-0' && !pic) {
        this.issues.push({
          level: 'error',
          title: '未上传封面图片',
          message: '您选择了自定义封面，需上传图片后才能下单哦',
          url: this.getCoverUrl(this.book)
        })
        return
      }

      if (!pic) {
        return
      }

      const coverTemplate = await this.$req.get('https://canvas.xinshu.me/config/' + codeName)
      const picLayer = coverTemplate.layers.find(i => i.name === 'pic')

      if (!picLayer) {
        return
      }

      pic = this.book.cover.pic.split('!')[0]

      if (/\.cdn\.xinshu.me|static\.xinshu\.me/.test(pic)) {
        pic = pic.replace(/^http:/, 'https:')
      }

      if (/^\/media\//.test(pic)) {
        pic = 'https://img.xinshu.me' + pic.replace('/media', '')
      }

      const {width, height} = await this.$req.get('https://media2.xinshu.me/info', {
        params: {
          url: pic
        }
      })

      const {w, h} = picLayer

      if (width < w / 2 || height < h / 2) {
        this.issues.push({
          level: 'warning',
          title: '封面图片过小',
          message: '图片尺寸 ' + width + '×' + height + ' 过小',
          suggestion: `重新上传图片至少达到 ${Math.round(w / 2)}×${Math.round(h / 2)}`,
          index: -1,
          url: pic
        })
      }

      if (codeName === 'cover-0' && Math.abs(width / height - 0.7) > 0.05) {
        this.issues.push({
          level: 'warning',
          title: '封面图片有拉伸',
          message: '图片比例与封面实际比例相差过大，会导致拉伸变形',
          suggestion: '参照 <a href="https://canvas.xinshu.me/generate/cover-0" target="_blank">封面样例</a> 修改后重新上传',
          index: -1,
          url: pic
        })
      }
    },
    async checkPages() {
      if (!this.book.isAlbum) {
        return
      }
      const pages = await this.$ajax.fetchAlbumPages({albumId: this.book.aid})
      if (this.book.tid === 'frame-moment') {
        const page = pages[0]
        const textLayer = page.layers.find(i => i.type === 'text')
        if (!textLayer || !textLayer.content) {
          this.issues.push({
            level: 'warning',
            title: '未撰写文字',
            index: 0,
            message: '您尚未撰写文本，可能会有美观问题',
            suggestion: '返回撰写文本'
          })
        } else {
          const {rows} = getLines(textLayer.content, textLayer)
          if (rows > textLayer.rows) {
            this.issues.push({
              level: 'warning',
              title: `文字溢出（${rows} / ${textLayer.rows} 行）`,
              index: 0,
              message: '您撰写的文本已超出文本区域，多余的文字会被截断',
              suggestion: '返回修改文本'
            })
          }
        }
        const meta = this.book.meta
        if (!meta.nickname) {
          this.issues.push({
            level: 'warning',
            title: '未指定作者名',
            index: 0,
            message: '您未指定作者名，使用默认作者名可能会影响美观',
            suggestion: '返回修改信息'
          })
        }
        if (!meta.avatar) {
          this.issues.push({
            level: 'warning',
            title: '未上传头像',
            index: 0,
            message: '您未上传自定义头像，使用默认头像可能会影响美观',
            suggestion: '返回修改信息'
          })
        }
      } else {
        const imageLayers = pages.flatMap(p => p.layers).filter(l => l.type === 'image')
        for (const page of pages) {
          const emptyLayers = page.layers.filter(l => l.type === 'image' && !l.content)
          if (emptyLayers.length) {
            this.issues.push({
              level: 'error',
              title: '图层缺少图片，请填充图片后继续',
              message: `第 ${pages.indexOf(page) * this.step + 1} 页缺少 ${emptyLayers.length} 张图片`
            })
          }
        }
        for (const layer of imageLayers) {
          if (layer.ratio < 0.8) {
            this.issues.push({
              level: 'warning',
              title: '图片尺寸过小',
              index: layer.pageIndex,
              url: layer.content,
              message: `当前图片仅 ${layer.w}×${layer.h}，印刷可能会模糊`,
              suggestion: `更换尺寸超过 ${layer.frameWidth}×${layer.frameHeight} 的图片`
            })
          }
        }
      }
    },
    async checkTypeset() {
      if (this.book.isAlbum) {
        if (this.book.pageCount < this.book.minPageCount && this.book.tid !== 'note') {
          this.issues.push({
            level: 'error',
            title: '页数有点少哦',
            message: `至少需要添加到 ${this.book.minPageCount * this.step} 页才能下单`
          })
        }
        return
      }

      const minPages = this.book.isAgentBook ? 20 : 40
      if (!this.book.finalized && this.book.pages < minPages) {
        this.issues.push({
          level: 'error',
          title: '页数有点少哦',
          message: `至少需要添加到 ${minPages} 页才能下单`,
          top: true
        })
      }

      if (this.book.needRepublish || this.book.needUpgrade || this.book.isTypesetting) {
        this.issues.push({
          title: '作品正在重新排版哦',
          message: '请稍后重新购买',
          level: 'error',
          top: true,
          refresh: true
        })
      }
    },
    async redeemBuy(doRedeem) {
      try {
        this.submitting = true
        if (doRedeem) {
          if (!this.redeemCode) {
            return this.$alert.error('请输入兑换码')
          }
          const redeemCode = await this.$req.post(
            '/api/redeem_code/validate',
            {redeemCode: this.redeemCode}
          )
          if (redeemCode.tid !== this.book.tid) {
            this.$alert.error('无法使用其他产品的兑换码加印')
            return
          }
        } else {
          const confirmed = await this.$dialog.confirm({
            content: '付费加印会将<b>复制</b>当前的作品，后续您可以在此作品的基础上无限次加印，是否继续'
          })
          if (!confirmed) {
            return
          }
        }
        const result = await this.$req.post('/jianxiang/api/1/albums/', {
          name: this.book.name,
          tid: this.book.tid,
          aid: this.book.aid,
          redeemCode: this.redeemCode
        })
        const confirmed = await this.$dialog.confirm({
          title: '下单确认',
          content: '照片书已复制，您需要在下单前再次编辑吗？',
          okTitle: '前往编辑',
          cancelTitle: '直接加印'
        })
        const bookId = this.getBookId(result)
        if (confirmed) {
          this.$router.push('/books/' + bookId)
        } else {
          // 已定稿的书不删除
          if (doRedeem && result.aid && /^a/.test(this.book.aid)) {
            this.$req.delete('/jianxiang/api/1/albums/' + this.book.aid + '/')
          }
          this.$ss.setItem('checked.' + bookId, 1)
          this.$router.push('/books/' + bookId + '/buy')
        }
      } finally {
        this.redeemCode = ''
        this.submitting = false
      }
    },
    async goPay(force, e = {}) {
      if (!force) {
        const confirmed = await this.$dialog.confirm('是否忽略可能的印刷问题并继续下单？')
        if (!confirmed) {
          return
        }
      }

      this.$ss.setItem('checked.' + this.bookId, 1)

      if (this.isAgent && !this.isDev && !this.isMiniProgram && !e.shiftKey) {
        // 代理跳转到代理中心支付
        location.replace('/agent/buy/' + this.bookId)
        return
      }

      const query = {...this.$route.query}

      if (this.user.referrerOpenid === 'oi7rhjs2SZGTjA8TOXF59TdACODk') {
        // 特定美国用户可购买电子版
        this.enablePdf = true
      }

      if (this.enablePdf && !this.isPartnerUser) {
        query.pdf = 1
      } else {
        query.pdf = undefined
      }

      this.$router.push({
        name: 'buy',
        params: this.$route.params,
        query
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.check {
  max-width: 560px;

  .month {
    border: 1px solid currentColor;
  }

  .problem {
    padding: 1rem 0;
    border-radius: 4px;
    word-break: break-all;
  }
}
</style>
