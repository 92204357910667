<template>
  <div class="image-zone">
    <grid :cols="cols" class="images" :initial="initial" :gutter="gutter">
      <grid-item :key="'img' + index" class="image" v-for="(image, index) in images">
        <square :src="image" @click="onImageClick(index)" border img-size="320"
                :mode="contain ? 'contain' : 'cover'"/>
        <div class="mask" v-if="$scopedSlots.mask || $slots.mask">
          <slot :image="image" :index="index" name="mask"></slot>
        </div>
      </grid-item>
    </grid>
  </div>
</template>

<script>
import preview from '@/mixins/preview-image'

export default {
  name: 'imageZone',
  mixins: [preview],
  props: {
    value: {
      default() {
        return []
      },
      type: Array
    },
    cols: {
      type: [String, Number],
      default: () => window.isMobile ? 4 : 5
    },
    gutter: String,
    initial: Boolean,
    contain: Boolean,
    disablePreview: Boolean,
    disabled: Boolean,
    mask: String
  },
  computed: {
    images() {
      return this.value.map(item => {
        return item.url || item.src || item
      })
    },
    cursor() {
      if (this.disabled || !this.$listeners.imageClick) {
        return 'default'
      }
      return 'pointer'
    }
  },
  methods: {
    onImageClick(index) {
      if (!this.disablePreview) {
        const image = this.images[index]
        this.$previewImage({url: image, urls: this.images})
      }
      this.$emit('image-click', this.value[index])
    }
  },
  data() {
    return {
      resize: 640
    }
  }
}
</script>

<style lang="scss" scoped>
.image-zone {
  margin-bottom: 1em;
}
</style>
