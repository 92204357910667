<template>
  <div class="orders">
    <page-title>
      我的订单
      <template slot="right">
        <b-btn @click="filtering = !filtering">
          <fa :icon="filtering ? 'caret-up' : 'search'"/>
          {{filtering ? '收起搜索' : '搜索订单'}}
        </b-btn>
      </template>
    </page-title>
    <b-card v-if="filtering">
      <b-row>
        <b-col cols="auto">
          <b-select v-model="binding" name="binding" @change="onFieldChange('binding', $event)">
            <option value="">全部</option>
            <option value="pdf">电子版</option>
            <option value="non_pdf">纸质版</option>
          </b-select>
        </b-col>
        <b-col>
          <b-input v-model="keyword" @change="onFieldChange('keyword', $event)"
                   placeholder="按书名搜索订单" name="keyword" type="search"/>
        </b-col>
      </b-row>
    </b-card>
    <template v-if="loading || scrolling">
      <b-card>
        <b-row align-v="center">
          <b-col cols="auto">
            <ske width="3em" type="button"/>
          </b-col>
          <b-col>
            <h5>
              <ske width="8em"/>
            </h5>
            <ske width="12em"/>
          </b-col>
        </b-row>
      </b-card>
      <b-row>
        <b-col sm="6" v-for="i in 2" :key="i">
          <b-card>
            <book-item :value="{}">
              <ske type="cover" slot="cover"/>
              <ske type="text" slot="title" width="10em"/>
              <template slot="info">
                <ske type="text" width="6em" block/>
                <ske type="text" width="6em" block/>
              </template>
            </book-item>
          </b-card>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-card v-if="hasPackageOrders && !filtering">
        <b-link to="/package-orders">
          <b-row align-v="center">
            <b-col cols="auto" class="text-primary">
              <fa icon="gift" size="3x" fad fw/>
            </b-col>
            <b-col class="text-body">
              <h5 class="mb-1">已购买的套装</h5>
              <div>点击此处开始订制产品</div>
            </b-col>
            <b-col cols="auto">
              <fa icon="chevron-right"/>
            </b-col>
          </b-row>
        </b-link>
      </b-card>

      <template v-if="currentOrders.length">
        <b-row>
          <b-col sm="6" v-for="order in currentOrders" :key="order.id" :data-id="order.id">
            <b-card>
              <book-item :value="order.book">
                <template slot="info">
                  <b-badge variant="info" v-if="order.binding === 'pdf'">电子版</b-badge>
                  <template v-if="order.book.fromDate">
                    <fa icon="calendar-alt" fw/>
                    <datetime :value="order.book.fromDate" format="YYYY.MM" beijing/>
                    ~
                    <datetime :value="order.book.toDate" format="YYYY.MM" beijing/>
                  </template>
                  <template v-else>
                    <fa icon="book-alt" fw/>
                    {{order.bookType | productName}}
                  </template>
                  <div>
                    {{$rmb(order.paidMoney)}} / {{order.count}}套
                    <template v-if="order.bindingName">
                      /
                      <template v-if="order.printType">{{getSize(order.printType)}}</template>
                      {{order.bindingName}}
                    </template>
                  </div>
                  <div>
                    <span v-if="order.binding === 'pdf' && order.status === 3">已完成</span>
                    <b-link v-b-modal="'logistics'" @click="activeOrder = order"
                            v-else-if="order.shipNo">已发货
                    </b-link>
                    <template v-else>{{order.status | orderStatus}}</template>
                    /
                    <datetime :value="order.createTime" format="YYYY.MM.DD"/>
                  </div>
                  <div>{{order.id}}</div>
                </template>
                <template slot="action">
                  <b-btn :to="'/orders/' + order.id">详情</b-btn>

                  <b-btn :to="'/pay/' + order.id" v-if="!order.paid" variant="primary">
                    <fa icon="credit-card"/>
                    付款
                  </b-btn>

                  <b-btn :to="'/orders/' + order.id + '/repair'" variant="warning"
                         v-else-if="order.repairStatus < 4">
                    <fa icon="wrench"/>
                    售后中
                  </b-btn>

                  <b-btn :to="'/orders/' + order.id + '/cashback'"
                         v-else-if="order.cashbackStatus < 4" variant="warning">
                    晒单审核中
                  </b-btn>

                  <b-btn :to="'/orders/' + order.id + '/cashback'"
                         v-else-if="order.enableCashBack" variant="warning">
                    晒单再送一本
                  </b-btn>

                  <template v-else-if="order.bookId">
                    <b-btn :to="'/books/' + order.book.bookId + '/buy?pdf=1'"
                           variant="primary">
                      <fa icon="shopping-cart"/>
                      加印 / PDF
                    </b-btn>
                  </template>
                </template>
              </book-item>
            </b-card>
          </b-col>
        </b-row>

        <footer class="mt-3">
          <b-pagination align="center" v-model="page" v-bind="pagination" @change="loadMore"/>
        </footer>
      </template>

      <template v-else-if="keyword || binding">
        <b-card class="text-center">
          <empty icon="smile">没有相关订单哦</empty>
        </b-card>
      </template>

      <template v-else>
        <b-card class="text-center">
          <empty emotion="cry">
            您还没有下过单呢
            <div class="btn-area" style="max-width: 320px;">
              <b-btn to="/books" block variant="primary">马上去下单</b-btn>
              <b-btn to="/unboxing" block>看看大家的晒单</b-btn>
            </div>
          </empty>
        </b-card>
      </template>

      <b-modal lazy id="logistics" title="订单物流" hide-footer>
        <template v-if="activeOrder">
          <p>以下是订单 {{activeOrder.id}} 的物流信息</p>
          <div>
            <b-row v-for="(item, i) in records" :key="i">
              <b-col cols="auto" class="nowrap">
                <b :class="{'mid-date': i > 0 && records[i - 1].date === item.date}">
                  {{item.date}}
                </b>
                <span class="ml-2">{{item.time}}</span>
              </b-col>
              <b-col cols="12" sm v-html="item.content"/>
            </b-row>
          </div>

          <div class="mt-1 text-right" v-if="activeOrder.shipNo">
            <ship :co="activeOrder.shipCompany" :no="activeOrder.shipNo" class="text-muted">
              前往快递公司网站查询
            </ship>
          </div>
        </template>
      </b-modal>
    </template>
  </div>
</template>

<script>
import routeData from '@/mixins/route-data'
import BookItem from './Book/BookItem'
import { chain } from 'lodash'

export default {
  name: 'orders',
  title: '我的订单',
  mixins: [routeData('orders')],
  components: {
    BookItem,
    Ship: require('@/components/Shipment').default
  },
  beforeRouteEnter(to, from, next) {
    if (to.query.orderNo) {
      return next('/orders/' + to.query.orderNo)
    }
    next()
  },
  data() {
    return {
      page: 1,
      pageSize: 12,
      scrolling: false,
      hasPackageOrders: false,
      totalCount: 0,
      activeOrder: null,
      filtering: false
    }
  },
  computed: {
    pagination() {
      return {
        perPage: this.pageSize,
        totalRows: this.totalCount
      }
    },
    keyword: {
      get() {
        return this.$route.query.keyword || ''
      },
      set() {
        return true
      }
    },
    binding: {
      get() {
        return this.$route.query.binding || ''
      },
      set() {
        return true
      }
    },
    currentOrders() {
      return this.orders.filter(i => !/^wm-|^l?salbum$/.test(i.bookType))
    },
    records() {
      const order = this.activeOrder
      if (!order) {
        return []
      }
      const records = order.logistics?.records || order.logistics?.data || []
      if (!records.length && order.paidTime) {
        records.push({
          time: order.paidTime,
          content: '商品已经下单'
        })

        if (order.shipTime && order.shipNo) {
          records.push({
            time: order.shipTime,
            content: '等待快递揽收'
          })

          if (this.$day(order.shipTime).diff(new Date(), 'hour') < -24) {
            records.push({
              time: new Date(),
              content: '暂无更多物流信息，请点击下方链接查看'
            })
          }
        }
      }

      return chain(records).map(i => {
        const moment = this.$day(i.time || i.AcceptTime)
        const content = i.content || i.context || i.AcceptStation
        const date = moment.format('YYYY-MM-DD')
        const time = moment.format('HH:mm:ss')
        const datetime = moment.toJSON()
        return {date, datetime, time, content}
      }).orderBy(i => new Date(i.datetime), 'desc').value()
    }
  },
  created() {
    this.$ajax.fetchPackageOrders().then(orders => {
      this.hasPackageOrders = orders.length > 0
    })
  },
  methods: {
    onLoad() {
      this.totalCount = this.orders.totalCount
      const {keyword, binding} = this.$route.query
      if (keyword || binding) {
        this.filtering = true
      }
    },
    onFieldChange(field, val) {
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          [field]: val || undefined
        })
      })
    },
    async loadMore(val) {
      try {
        this.scrolling = true
        this.orders = await this.fetchOrders({page: val})
        this.page = val
      } finally {
        this.scrolling = false
      }
    },
    getSize(str) {
      if (!str) {
        return ''
      }
      return str.split('-')[0].toUpperCase()
    }
  }
}
</script>

<style lang="scss" scoped>
img[alt="arrow"] {
  position: absolute;
  bottom: -1.6rem;
  right: 40%;
  height: 1.5em;
}
</style>
