const div = document.createElement('div')
div.style.whiteSpace = 'pre-wrap'
div.style.wordBreak = 'break-all'
div.style.lineHeight = 1
div.style.padding = 0
div.style.margin = 0
div.style.position = 'fixed'
div.style.opacity = 0
div.style.visibility = 'hidden'
div.style.pointerEvents = 'none'

export default function getLines(content, {textWidth, cols, fontSize = 16, spacing = 0, maxRows}) {
  if (cols) {
    textWidth = cols * fontSize * (1 + spacing)
  }
  if (spacing) {
    textWidth /= (1 + spacing)
  }
  if (!content || !textWidth) {
    return {rows: 0, height: 0}
  }
  content = content.replace(/\s*$/, '')
  div.style.width = Math.round(textWidth) + 'px'
  div.style.fontSize = fontSize + 'px'
  div.textContent = content
  document.body.appendChild(div)
  const height = div.clientHeight
  const rows = height / fontSize
  document.body.removeChild(div)
  return {
    rows: Math.round(rows),
    height,
    maxRows,
    exceeded: rows >= maxRows,
    maxLength: rows >= maxRows ? content.length : content.length * 2
  }
}
