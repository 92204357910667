<template>
  <b-link class="shipment" :href="shipmentLink" target="_blank">
    <slot></slot>
  </b-link>
</template>

<script>
export default {
  name: 'ship',
  props: {
    co: {
      default: '邮政快递',
      type: String
    },
    no: {
      type: String
    }
  },
  computed: {
    shipmentLink() {
      return 'https://weixinshu.com/pages/shipment?shipCompany=' + this.co + '&shipNo=' + this.no
    }
  }
}
</script>

<style scoped lang="scss">
  .ship {

  }
</style>
