<template>
  <div class="qrcode" :style="styles">
    <div class="image">
      <square :src="qrImage">
        <slot></slot>
      </square>
    </div>
  </div>
</template>

<script>
export default {
  name: 'qrcode',
  props: {
    src: String,
    size: String,
    text: String,
    shorten: Boolean,
    raw: Boolean,
    icon: String,
    margin: {
      type: Number,
      default: 3
    }
  },
  computed: {
    styles() {
      if (this.size) {
        return {width: this.size}
      }
      return {}
    },
    qrImage() {
      if (this.raw) {
        return this.src
      }
      const params = {
        string: this.text,
        qrcode: this.src,
        margin: this.margin,
        shorten: this.shorten ? 1 : 0,
        icon: this.icon
      }
      if (!this.text && !this.src) {
        return ''
      }
      return 'https://canvas.xinshu.me/qrcode' + this.serialize(params)
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
  .qrcode {
    position: relative;
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .square {
    background-color: #eee;
  }
</style>
