<template>
  <div class="repair">
    <h2 class="page-title">订单售后</h2>
    <loading v-if="loading"/>
    <template v-else>
      <template v-if="hasTicket">
        <b-card>
          <span class="float-right">
            <b-badge variant="success" v-if="finished">已完成</b-badge>
            <b-badge variant="primary" v-else>处理中</b-badge>
          </span>

          <header class="mb-2">
            <h5 class="card-title mb-1">您已提交售后申请</h5>
            <datetime class="text-muted" :value="repair.createAt" format="LL LT"/>
          </header>

          <b-form-group label="描述">
            <div class="text-pre-wrap">{{repair.content}}</div>
          </b-form-group>

          <image-zone :value="repair.picList" v-if="repair.picList"/>
        </b-card>

        <b-card title="留言" v-if="!finished">
          <b-form-group>
            <textarea class="form-control" placeholder="说点什么吧" rows="2" v-autosize
                      v-model="reply.content"></textarea>
          </b-form-group>

          <upload-zone cols="5" size="320x320" v-model="reply.picList"/>

          <div class="mt-3">
            <b-btn :disabled="sending" @click="sendReply" variant="primary">
              <fa icon="spinner" spin v-if="sending"/>
              <fa icon="paper-plane" v-else/>
              发送
            </b-btn>
          </div>
        </b-card>

        <b-card>
          <template v-if="replies.length">
            <section :key="item.id" class="message" v-for="item in replies">
              <header class="mb-2">
                <span>
                  <avatar :src="item.replier.avatar" size="1.5em"></avatar>
                  <b>{{item.replier.name}}</b>
                </span>
                <datetime class="text-muted float-right" :value="item.createAt"/>
              </header>
              <div class="text-pre-wrap">{{item.content}}</div>
              <image-zone :value="item.picList" v-if="item.picList && item.picList.length"/>
            </section>
          </template>

          <empty icon="comments" v-else>
            <div>暂无回复，有回复时会通过公众号推送给您哦</div>
            <div>如果您还没有关注公众号，请扫描下方二维码关注「心书」</div>
            <div>
              <img
                src="https://canvas.xinshu.me/qrcode?qrcode=https%3A%2F%2Fmp.weixin.qq.com%2Fcgi-bin%2Fshowqrcode%3Fticket%3DgQG98DwAAAAAAAAAAS5odHRwOi8vd2VpeGluLnFxLmNvbS9xLzAyWkZpZkVlYldieGUxMDAwME0wN3UAAgTZ2i1hAwQAAAAA&icon=weixinshu"
                alt="" style="max-width: 160px;">
            </div>
          </empty>
        </b-card>
      </template>

      <template v-else>
        <b-card title="售后规则">
          <ol class="mb-0">
            <li :key="rule" v-for="rule in rules" v-html="rule"></li>
          </ol>
        </b-card>

        <form @submit.prevent="onSubmit" novalidate>
          <b-card title="提交售后">
            <b-form-group label="文字描述（必须）">
              <textarea class="form-control" minlength="1" placeholder="写下您遇到的问题"
                        required rows="3" v-autosize v-model="ticket.content"></textarea>
            </b-form-group>

            <b-form-group>
              <template slot="label">
                实物照片（必须，最多 3 张）
                <div class="float-right">
                  <b-link v-b-modal="'example'">
                    <fa icon="image"/>
                    示例图片
                  </b-link>
                </div>
              </template>
              <upload-zone cols="3" :max="3" min-size="320x320" v-model="ticket.picList"/>
            </b-form-group>
          </b-card>

          <div class="btn-area">
            <b-btn :disabled="submitting" block type="submit" variant="primary">
              <fa icon="spinner" spin v-if="submitting"/>
              提交
            </b-btn>
          </div>
        </form>

        <b-modal hide-footer id="example" title="图片示例">
          <p>自然光下拍摄，能反映纸质书问题的清晰照片</p>
          <h5>示例1</h5>
          <p>问题：相册一角损毁</p>
          <p>
            <square mode="contain" border
                    src="https://img.xinshu.me/resource/90d434a72a6a4813bca46d6b90cd4400"/>
          </p>
          <hr>
          <h5>示例2</h5>
          <p>问题：图片印刷出错，好几张图片只有半张</p>
          <p>
            <square mode="contain" border
                    src="https://img.xinshu.me/resource/29cf7add14504384b4999f4905d0c4b9"/>
          </p>
          <hr>
          <h5>示例3</h5>
          <p>问题：装订有问题，书页全部散架了</p>
          <p>
            <square mode="contain" border
                    src="https://img.xinshu.me/resource/b7dddfc4bfca4ac4ab22912c46d7cd58"/>
          </p>
        </b-modal>
      </template>
    </template>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import ImageZone from '@/components/ImageZone.vue'
import UploadZone from '@/components/UploadZone.vue'

import routeData from '@/mixins/route-data'

export default {
  name: 'repair',
  title: '订单售后',
  mixins: [routeData('repair')],
  components: {UploadZone, ImageZone},
  data() {
    return {
      ticket: {
        title: this.$route.params.orderNo + ' - 订单售后',
        status: 0,
        priority: 1,
        department: 0,
        content: '',
        categoryInfo: this.$route.params.orderNo,
        category: 4,
        picList: []
      },

      submitting: false,
      sending: false,
      reply: {
        content: '',
        picList: []
      },

      rules: [
        '请上传问题纸质书凭证（自然光下拍摄的清晰照片）',
        '我们将在3个工作日内核实，并通过「心书」公众号回复您处理方案'
      ]
    }
  },
  computed: {
    replies() {
      const results = this.repair?.replys || []
      return results.slice().reverse()
    },
    success() {
      return this.repair && this.repair.status >= 3
    },
    failed() {
      return this.repair && this.repair.status === 5
    },
    finished() {
      return this.success || this.failed
    },
    hasTicket() {
      return !isEmpty(this.repair)
    }
  },
  methods: {
    async onSubmit() {
      if (!this.ticket.content) {
        this.$alert.error('请写下您遇到的问题')
        return
      }

      if (this.ticket.picList.length < 1) {
        this.$alert.error('请至少上传一张图片反映您遇到的问题')
        return
      }

      const confirmed = await this.$dialog.confirm({
        title: '售后申请',
        content: '提交申请后工作人员将会对您的申请进行审核，您也可以继续在本页面留言与工作人员沟通。'
      })

      if (!confirmed) {
        return
      }

      const data = Object.assign({}, this.ticket)
      data.picList = data.picList.map(pic => pic.url)

      try {
        this.submitting = true
        await this.$req.post('/api/helpdesk/request/add', data)
        this.$alert.success('售后申请已提交')
        this.updateRepair()
      } finally {
        this.submitting = false
      }
    },
    async sendReply() {
      if (!this.reply.content) {
        this.$alert.error('请填写留言内容')
        return
      }
      this.sending = true
      try {
        await this.$req.post(`/api/helpdesk/request/${this.repair.id}/reply/add`, {
          content: this.reply.content,
          picList: this.reply.picList.map(pic => pic.url)
        })
        this.updateRepair()
        this.reply.content = ''
        this.reply.picList = []
      } catch (err) {
      }
      this.sending = false
    }
  }
}
</script>

<style lang="scss" scoped>
.repair {
  max-width: 560px;

  ol {
    padding-left: 1.25em;

    li {
      line-height: 1.8;
    }
  }

  .image-zone {
    margin: 0;
  }

  .message {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $hr-border-color;

    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
}

#example {
  .square {
    max-width: 320px;
  }
}
</style>
