<template>
  <div class="pdf">
    <h2 class="page-title">下载 PDF</h2>
    <loading v-if="loading"/>
    <template v-else>
      <p v-if="divisions.length > 2">为防止PDF文件大小过大，已为您自动分册</p>
      <b-row>
        <b-col v-for="(item, index) in divisions" :key="item.url" sm="6">
          <b-card>
            <table class="table table-sm table-borderless">
              <tbody>
              <tr>
                <td>作品名</td>
                <td>《{{item.name}}》</td>
              </tr>
              <tr>
                <td>分册</td>
                <td>第 {{index + 1}} 册 / 共 {{divisions.length}} 册</td>
              </tr>
              <tr>
                <td>创建时间</td>
                <td>
                  <datetime :value="item.createdAt"/>
                </td>
              </tr>
              <tr>
                <td>过期时间</td>
                <td>
                  <datetime :value="item.expiredAt"/>
                </td>
              </tr>
              <tr>
                <td>文件大小</td>
                <td>{{item.size / 1024 / 1024 | round(2)}}MB</td>
              </tr>
              </tbody>
            </table>
            <b-btn v-if="item.expiredAt <= new Date()" disabled>PDF文件已过期</b-btn>
            <b-btn variant="primary" :href="item.url" target="_blank" v-else>下载PDF</b-btn>
          </b-card>
        </b-col>
      </b-row>

      <b-btn block variant="link" @click="$router.back()">返回</b-btn>
    </template>
  </div>
</template>

<script>
import route from '@/mixins/route-data'

export default {
  name: 'pdf',
  title: '下载PDF',
  mixins: [route('order'), route('divisions')],
  computed: {
    expiredAt() {
      return this.$day(this.order.paidTime).add(30, 'day').toJSON()
    },
    hasExpired() {
      return new Date() > new Date(this.expiredAt)
    }
  },
  methods: {
    onLoad() {
      this.divisions = this.divisions.filter(i => i.pdfType === 'inner').map(i => {
        i.createdAt = new Date(i.createdAt)
        i.expiredAt = this.$day(i.createdAt).add(30, 'day').toDate()
        return i
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.pdf {
  max-width: 640px;

  .table-responsive {
    margin-bottom: 0;
  }

  .table {
    white-space: nowrap;
  }
}
</style>
