<template>
  <div class="agent-buy">
    <loading v-if="loading"/>
    <form @submit.prevent="onSubmit" name="orderForm" v-else>
      <b-card no-body class="card-book-info">
        <b-card-header>作品信息</b-card-header>
        <b-card-body>
          <b-row align-v="center">
            <b-col style="max-width: 7rem;">
              <cover :book="book"/>
            </b-col>
            <b-col style="min-width: 0;">
              <div class="info">
                <p class="book-title">
                  <book-icon :value="book.bookType" colorful/>
                  <b>{{book.title}}</b>
                </p>
                <div class="text-overflow" v-if="book.author">作者：{{book.author}}</div>
                <div v-else>产品：{{book.tid | productName}}</div>
                <div>排版：{{book.typesetName}}</div>
                <div>页数：{{book.pages}}</div>
              </div>

              <div class="mt-2 small" v-if="!isSample && book.pages > 240">
                <fa icon="exclamation-triangle"/>
                已超过 240 页，系统将会为您自动分册
                <a href="javascript:" v-b-modal.divisionRules>点击查看分册原则</a>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <b-modal id="divisionRules" title="分册原则" hide-footer>
        <img alt="分册说明图片" src="https://img.xinshu.me/upload/c3045c4527328079cdd03c41e0c4b813">
      </b-modal>

      <template v-if="purchaseError">
        <b-card no-body>
          <b-card-header>
            <fa icon="exclamation-triangle"/>
            {{purchaseError.title}}
          </b-card-header>
          <b-card-body class="text-primary">
            <div v-html="purchaseError.content"></div>
            <div class="mt-2" v-if="purchaseError.type === 'forceContact'">
              <b-btn @click="copyInfo" block>复制信息</b-btn>
            </div>
          </b-card-body>
        </b-card>

        <b-card no-body v-if="agentInfo">
          <b-card-header>
            <fa icon="phone-office"/>
            联系方式
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col cols="6" v-if="agentInfo.supplementary.shopName">
                <b>淘宝店</b>
                <div class="mt-1">
                  <b-link :href="agentInfo.supplementary.shopLink"
                          v-if="agentInfo.supplementary.shopLink"
                          target="_blank">{{agentInfo.supplementary.shopName}}
                  </b-link>
                  <span v-else>{{agentInfo.supplementary.shopName}}</span>
                </div>
              </b-col>
              <b-col cols="6" v-if="agentInfo.supplementary.wxid">
                <b>微信号</b>
                <div class="mt-1">{{agentInfo.supplementary.wxid}}</div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <div class="btn-area">
          <b-btn block to=".." variant="link" exact>返回</b-btn>
        </div>
      </template>

      <template v-else>
        <b-card header="装帧选择" class="card-binding" v-if="choices.binding">
          <select-group v-model="order.binding" :options="choices.binding" required/>
        </b-card>

        <b-card no-body class="card-count">
          <b-card-header>数量选择</b-card-header>
          <b-card-body>
            <div class="row align-items-center">
              <div class="col">
                单价:
                <span class="text-primary">{{$rmb(price.unitPrice)}}</span>
                <div v-if="price.text">{{price.text}}</div>
              </div>
              <div class="col text-right" style="max-width: 12em;">
                <stepper v-model="order.count" required></stepper>
              </div>
            </div>
          </b-card-body>
        </b-card>

        <b-card header="快递选择" class="card-delivery" v-if="choices.delivery">
          <select-group v-model="order.delivery" :options="choices.delivery"/>
        </b-card>

        <b-card header="价格详情" class="card-price">
          <div>
            <span>原价{{$rmb(price.unitPrice)}} × {{order.count}}</span>
            <span class="float-right">{{$rmb(price.unitPrice * order.count)}}</span>
          </div>
          <template v-if="price.details">
            <div class="mt-2" v-for="item in price.details" :key="item.title">
              <span>{{item.title}}</span>
              <span class="float-right" v-if="item.value">{{$rmb(item.value)}}</span>
              <span class="float-right" v-else-if="item.discount">
                × {{item.discount | couponValue}}
              </span>
            </div>
          </template>
          <hr>
          <div>
            订单总价
            <b class="text-primary float-right">
              <span v-if="calculating">正在计算</span>
              <span v-else>{{$rmb(price.agentClientPrice)}}</span>
            </b>
          </div>
        </b-card>

        <b-card header="收件信息" class="card-address">
          <b-row>
            <b-col class="col">
              <b-form-group label="收件人">
                <b-input maxlength="20" v-model="order.consignee" v-ls placeholder="收件人姓名"/>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="手机号">
                <b-input type="tel" maxlength="11" v-model="order.phone" v-ls placeholder="收件人手机号"/>
              </b-form-group>
            </b-col>
          </b-row>
          <address-picker class="mb-3" v-model="order.address" cache>
            <label slot="title">地址信息</label>
          </address-picker>
          <b-form-group class="mb-0" label="订单备注">
            <template v-if="showNote">
              <textarea name="note" id="note" class="form-control" rows="3"
                        placeholder="建议填写与客服协商一致的信息"
                        v-model="order.note" maxlength="150"></textarea>
              <div class="mt-2">
                <div class="float-right">
                  <a href="javascript:" @click="finishNote">完成</a>
                </div>
              </div>
            </template>
            <template v-if="!showNote">
              <div class="note">
                <template v-if="order.note">{{order.note}}</template>
                <a href="javascript:" @click="showNote = true">{{order.note ? '修改' : '添加订单备注'}}</a>
              </div>
            </template>
          </b-form-group>
        </b-card>

        <b-card header="支付方式">
          <cell title="支付宝支付" val="alipay" v-model="order.payType">
            <template slot="body">
              <img src="../assets/images/icons/alipay.svg" class="icon" alt>
              支付宝支付
            </template>
          </cell>

          <cell title="微信支付" val="wxpay" v-model="order.payType">
            <template slot="body">
              <img src="../assets/images/icons/wxpay.svg" class="icon" alt>
              微信支付
            </template>
          </cell>
        </b-card>

        <div class="btn-area">
          <b-btn variant="primary" block disabled v-if="creating">
            <i class="far fa-credit-card"></i>
            正在创建订单
          </b-btn>
          <b-btn variant="primary" type="submit" block v-else>
            <fa icon="credit-card"/>
            下单支付 {{$rmb(price.agentClientPrice)}}
          </b-btn>
        </div>
      </template>
    </form>
  </div>
</template>

<script>
import { debounce, find, forEach, get } from 'lodash'

import routeData from '@/mixins/route-data'
import bookUtils from '@/mixins/book-utils'

import Stepper from '@/components/Stepper'
import AddressPicker from '@/components/AddressPicker'
import copyText from 'clipboard-copy'

export default {
  name: 'agentBuy',
  title: '下单购买',
  mixins: [
    bookUtils,
    routeData('book'),
    routeData('price', {query: {isAgent: true}})
  ],
  components: {Stepper, AddressPicker},
  data() {
    return {
      orderTips: null,
      agentInfo: null,
      order: {
        count: 1,
        binding: 1,
        delivery: '',
        couponNo: '',

        consignee: '',
        phone: '',
        address: '',

        payType: 'alipay'
      },

      creating: false,
      calculating: false,
      showNote: false
    }
  },
  computed: {
    purchaseError() {
      if (this.book.pages < 20) {
        return {
          title: '未到达指定页数',
          content: '作品小于20页，无法为您印刷，再增加些内容吧'
        }
      }
      if (this.cannotBuy) {
        return {
          type: 'forceContact',
          title: '暂不支持自助下单',
          content: '请复制作品信息，联系商家客服或做书小编下单'
        }
      }
      return null
    },
    cannotBuy() {
      return this.price.oriPrice === null || get(this.agentInfo, 'supplementary.priceRatio') === 0
    },
    isSample() {
      return /^TY/i.test(this.order.binding)
    },
    choices() {
      return this.price.choices || {}
    },
    priceRelative() {
      return [
        this.order.count,
        this.order.binding,
        this.order.address,
        this.order.couponNo,
        this.order.delivery
      ].join()
    },
    cacheParams() {
      return [
        this.order.consignee,
        this.order.phone,
        this.order.address
      ].join()
    },
    errors() {
      const errors = []
      if (!this.order.consignee) {
        errors.push({
          message: '请补全收货人信息',
          type: 'consignee'
        })
      }
      if (/(test|测试)|(^\d+$)/.test(this.order.consignee)) {
        errors.push({
          message: '请填写正确的收货人信息',
          type: 'consignee'
        })
      }
      if (!this.order.phone) {
        errors.push({
          message: '请补全手机号',
          type: 'phone'
        })
      }
      if (!/1[345789]\d{9}/.test(this.order.phone)) {
        errors.push({
          message: '手机号格式错误',
          type: 'phone'
        })
      }
      if (!this.order.address) {
        errors.push({
          message: '请补全地址信息',
          type: 'address'
        })
      }
      if (!this.order.count) {
        errors.push({
          message: '请选择数量',
          type: 'count'
        })
      }
      return errors
    }
  },
  created() {
    this.$watch('priceRelative', debounce(this.calculatePrice, 250))
  },
  methods: {
    onLoad() {
      if (this.price.defaults) {
        Object.keys(this.price.defaults).forEach(key => {
          this.order[key] = this.price.defaults[key]
        })
      }
      if (this.book.agentOpenid) {
        this.$req.get('/api/agents/info/' + this.book.agentOpenid).then(result => {
          this.agentInfo = result
        })
      }
    },
    copyInfo() {
      const info = this.$el.querySelector('.info').innerText.replace('\n\n', '\n').trim()

      copyText(info).then(() => {
        this.$alert.success('作品信息已复制')
      }).catch(() => {
        this.$alert.error('复制失败，请手动复制上方作品信息')
      })
    },
    onSubmit() {
      if (this.errors.length) {
        const error = this.errors[0]
        this.$alert.error(error.message)
        this.$el.querySelector('#' + error.type).focus()
        return
      }
      const order = this.order
      const orderData = Object.assign({}, {
        count: order.count,
        binding: order.binding.toString(),

        bookId: this.book.id,
        bookType: this.book.bookType,

        isMobile: window.isMobile ? 1 : 0,

        // 收货人信息
        consignee: order.consignee,
        phone: order.phone,
        addr: order.address,
        note: order.note,

        // 价格相关
        payType: order.payType,
        shipCompany: this.$options.filters.shipCompany(order.delivery)
      })

      this.creating = true

      return this.createOrder(orderData).then(data => {
        if (data.paidMoney === 0) {
          this.$alert.success('下单成功，无需支付')
          this.$router.push('/orders/' + (data.id || data.orderId) + '/result')
          return
        }
        this.$alert.success('下单成功，请继续支付')
        this.$router.push('/pay/' + (data.id || data.orderId))
      }).catch(() => {
        this.creating = false
        this.$alert.error('订单创建失败，请稍后重试')
      })
    },
    createOrder(order) {
      return this.$req.post('/api/order/agent_client', order)
    },

    calculatePrice() {
      return new Promise((resolve, reject) => {
        clearTimeout(this.calculate)
        this.calculating = true
        this.calculate = setTimeout(async () => {
          try {
            const params = {...this.order}
            const result = await this.fetchPrice(params)
            this.price = result
            this.choices = result.choices

            if (result.choices) {
              // 用来渲染装帧、快递等可选项
              if (result.choices.binding && !this.enablePdf) {
                result.choices.binding = result.choices.binding.filter(i => i.value !== 'pdf')
              }

              forEach(result.choices, (val, key) => {
                // 如果 choice 中不包含当前选择的项，则使用 defaults 中提供的默认选项
                // 如用户初始选择的是圆通
                // 修改地址后无法选择圆通，就改为返回的默认值顺丰
                if (!find(val, {value: this.order[key]})) {
                  this.order[key] = result.defaults[key]
                }
              })
            }

            return resolve(result)
          } catch (err) {
            reject(err)
          } finally {
            this.calculating = false
          }
        }, 200)
      })
    },

    finishNote() {
      this.showNote = false
    },

    fetchOrderTips() {
      return this.$req.get('/buckets/weixinshu:agentOrderTips').then(data => {
        this.orderTips = data
      })
    }
  }
}
</script>

<style scoped lang="scss">
.agent-buy {
  max-width: 640px;

  .book-title {
    font-size: 1.25em;
    margin-bottom: .5rem;
  }

  .card-header {
    background-color: #fff;
    border-bottom: 0;
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 0;
    padding-bottom: 0;

    .float-right {
      font-size: .8em;
      font-weight: normal;
    }
  }

  .card {
    margin-bottom: $grid-gutter-width-base / 2;
  }

  .btn-select {
    margin-right: .5em;
  }

  .coupons {
    margin-top: 1em;
  }

  .pointer {
    cursor: pointer;
  }

  .icon {
    width: 2em;
    height: auto;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: .5em;
  }

  .bottom {
    padding-left: $grid-gutter-width-base / 2;
  }

  ::v-deep #typesetDetail {
    .image {
      margin-left: -15px;
      margin-right: -15px;
    }

    .nav-pills {
      justify-content: center;
    }
  }
}
</style>
